import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// layouts
import InnerHeader from "./innerHeader";
// includes
import { api, common, types } from "helpers";
import { ProgressBar } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

const InnerLayout = (props) => {
  const pathname = common.getPath(useLocation().pathname);
  const navigate = useNavigate();
  // state
  const [open, setOpen] = useState(true);
  const [menuDefault, setMenuDefault] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [authInfo] = useState(common.getAuth());
  const [isAuth] = useState(common.checkAuth());
  const [isAllow, setIsAllow] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [userInfoStatus, setUserInfoStatus] = useState("process");
  const [icons, setIcons] = useState(
    types.menus[authInfo.role].map((item) => item.icon)
  );

  const defaultIcons = types.menus[authInfo.role].map((item) => item.icon);
  const hoveredIcons = types.menus[authInfo.role].map(
    (item) => item.hoveredIcons
  );
  // effect
  useEffect(() => {
    if (!isAuth) {
      common.redirect("");
    } else if (props.role === "common") {
      setIsAllow(true);
    } else {
      if (authInfo.role == "sub_agent") {
        setIsAllow(true);
        navigate("/agent/home");
      } else if (authInfo.role !== props.role) {
        common.redirect(authInfo.role + "/home");
      } else {
        setIsAllow(true);
      }
    }
  }, [isAuth]);

  useEffect(() => {
    if (isAllow) {
      if (authInfo.role === "student") {
        myProgress();
      } else {
        setUserInfoStatus("success");
      }
    }
  }, [isAllow]);

  useEffect(() => {
    if (pathname === "course") {
      setOpen(false);
    } else {
      setOpen(menuDefault);
    }
  }, [pathname]);

  // handler
  const handleTab = (activeTab) => {
    if (currentTab !== activeTab) {
      setCurrentTab(activeTab);
    }
  };

  // api
  const myProgress = () => {
    let data = {
      url: "student_info",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = common.reParse(res.data);
        // set pickeer
        result.course.list = [];
        result.course.picked.map((i) =>
          result.course["list"].push(i.course._id)
        );
        setUserInfo(result);
        setUserInfoStatus("success");
      } else {
        setUserInfoStatus("error");
      }
    });
  };

  // functions
  const outletAction = (key, value) => {
    if (key === "myProgress") {
      myProgress();
    }
  };

  const handleDrawer = () => {
    if (pathname !== "course") {
      setOpen(!open);
      setMenuDefault(!open);
    }
  };

  const iconHover = (index, hovered, activePath) => {
    if (!activePath) {
      if (hovered) {
        let ic = defaultIcons;
        ic[index] = hoveredIcons[index];

        setIcons((prev) => [...ic]);
      } else {
        let ic = defaultIcons;
        ic[index] = defaultIcons[index];
        setIcons((prev) => [...ic]);
      }
    }
  };

  return (
    isAuth &&
    isAllow && (
      <Fragment>
        <InnerHeader handleDrawer={handleDrawer} socket={props?.socket} />
        <div className="d-flex body-wrap">
          <aside
            className={!open ? "sidebar-nav-wrap toggle" : "sidebar-nav-wrap"}
          >
            {" "}
            {/* Add className "toggle" here to toggle the sidebar */}
            <ul className="sb-nav">
              {types.menus[authInfo.role].map((i, k) => (
                <li
                  key={k}
                  id={k}
                  className={pathname === i.menuKey ? "active" : "in-active"}
                // title={
                //   i.menuKey === "agent_promoters" ||
                //   i.menuKey === "agent_promo"
                //     ? authInfo?.isPromotor !== true
                //       ? i.name
                //       : "You can't access this menu"
                //     : i.name
                // }
                >
                  <ReactTooltip
                    anchorId={k}
                    place="bottom"
                    content={
                      i.menuKey === "agent_promoters" ||
                        i.menuKey === "agent_promo"
                        ? authInfo?.isPromotor === true
                          ? i.name
                          : "You can't access this menu"
                        : i.name
                    }
                  />
                  <Link
                    // to={i.url}
                    to={
                      i.menuKey === "agent_promoters" ||
                        i.menuKey === "agent_promo"
                        ? authInfo?.isPromotor === true
                          ? i.url
                          : "#"
                        : i.url
                    }
                    onClick={() => handleTab(k)}
                    onMouseEnter={() =>
                      iconHover(k, true, pathname === i.menuKey)
                    }
                    onMouseLeave={() =>
                      iconHover(k, false, pathname === i.menuKey)
                    }
                    className={
                      i.menuKey === "agent_promoters" ||
                        i.menuKey === "agent_promo"
                        ? authInfo?.isPromotor === true
                          ? "active-menu"
                          : "disable-menu"
                        : "active-menu"
                    }
                  >
                    <div className="d-flex align-items-center">
                      <img src={common.loadImg(icons[k])} alt="HeaderProfile" />{" "}
                      <span>
                        {i.name}
                        {i.progress && (
                          <span className="menu-progress-wrap mt-2">
                            <label className="progress-txt">
                              {userInfo?.progress?.total}%
                            </label>
                            <ProgressBar
                              now={userInfo?.progress?.total}
                              className="progressbar-height mt-1 ms-1"
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </Link>
                </li>
              ))}
              {/* <li>
                <Link to="#" onClick={onLogout}>
                  <img src={common.loadImg("logout-icn.svg")} alt="logout" />
                  <span>Logout</span>
                </Link>
              </li> */}
            </ul>
          </aside>
          <div className={!open ? "w-100 pl-toggle" : "w-100 pl-max"}>
            <Outlet
              {...props}
              context={[outletAction, authInfo, userInfo, userInfoStatus, open]}
            />
          </div>
        </div>
      </Fragment>
    )
  );
};

export default InnerLayout;
