import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
// includes
import { common } from "helpers";
import { api } from "helpers/api";
import FireBase from "../../../helpers/firebase";

export default function One(props) {
  // state
  const [viewA, setViewA] = useState(false);
  const [viewB, setViewB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deviceToken, setDeviceToken] = useState(null);
  // form
  const formFields = {
    firstName: {
      validate: ["req#First Name is required"],
    },
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
      ],
    },
    password: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Password and confirm password do not match",
      ],
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    FireBase.getDeviceToken(setDeviceToken);
  }, []);

  // Handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      register();
    }
  };

  // Api
  const register = () => {
    setLoading(true);
    let payload = {
      name: fields?.firstName,
      email: fields?.email,
      password: fields?.password,
      userType: "AGENT",
      webDevToken: deviceToken,
      registeredFrom: "Web",
    };
    let data = {
      url: "ids_sign_up",
      method: "POST",
      auth: "none",
      body: payload,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        common.localDataSet({
          name: fields.firstName,
          email: fields.email,
          password: fields.password,
          userType: "AGENT",
        });
        props?.onSuccess();
      } else if (res.status === 500) {
        setError(res.data.error);
      }
      setLoading(false);
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-md-12">
            <div className="right-content-width remove-border">
              {/* <p className="ref-heading1">Register as a Partner</p> */}

              <div className="agent-box-body">
                <div className="row">
                  <div className="col-md-12">
                    <label className="label-padding">First Name</label>
                    <div className="signin-form-row">
                      <input
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                        value={fields.firstName}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>

                    <p className="error-txt">{errors.firstName}</p>
                  </div>
                  <div className="col-md-12">
                    <label className="label-padding">Email</label>
                    <div className="signin-form-row">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        value={fields.email}
                        onChange={(e) => {
                          handleChange(e);
                          setError(null);
                        }}
                        className="form-control"
                      />
                    </div>
                    <p className="error-txt">{error ?? errors.email}</p>
                  </div>
                </div>

                <div className="row  ">
                  <div className="col-md-12">
                    <label className="label-padding">Password</label>
                    <div className="signin-form-row">
                      <input
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        value={fields.password}
                        onChange={handleChange}
                        maxLength={15}
                        className="form-control"
                      />
                    </div>
                    <p className="error-txt">{errors.password}</p>
                  </div>
                  <div className="col-md-12">
                    <label className="label-padding">Confirm Password</label>
                    <div className="signin-form-row">
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Enter confirm password"
                        value={fields.confirmPassword}
                        onChange={handleChange}
                        maxLength={15}
                        className="form-control"
                      />
                    </div>
                    <p className="error-txt">{errors.confirmPassword}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">&nbsp;</div>
                  <div className="col-lg-12 register-step-agent-footer">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit()}
                      disabled={loading}
                    >
                      {!loading ? "Next" : "Processing"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
