import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Container, Row, Col } from "react-bootstrap";
import { common } from "helpers";
import { Prev } from "react-bootstrap/esm/PageItem";

const file_size_limit = '10MB';
const file_max_size = 10485760;

const FileUpload = (props) => {
  const { id, multiple } = props;
  // document
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    let error = [];
    fileRejections.map((file) => {
      file.errors.map((err) => {
        if (err.code === "file-too-large") {
          error.push("File is larger than " + file_size_limit);
        } else {
          error.push(err.message);
        }
      });
    });
    if (error.length > 0) {
      common.notify("E", error[0]);
    } else if (props.onSuccess) {
      props?.onSuccess({ id, acceptedFiles });
    }
  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop,
    multiple: multiple == false ? false : true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 15,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg", ".pdf", ".doc", ".docx", ".zip"],
    },
    maxSize: file_max_size
  });

  return (
    <Col md={12} className="mt-2 bordertop">
      <label className="form-label mt-2">
        {props?.label ? props?.label : "Document Attachments"}
      </label>
      <div className="et-form-row" onClick={open}>
        <div
          {...getRootProps({ className: "dropzone" })}
          className="file-drag-drop-wrap mt-2"
        >
          <input {...getInputProps()} />
          <p className="drag-drop-input-lable mb-0">
            Drag and drop files here or{" "}
            <button type="button" className="browse-btn ms-2">
              Browse
            </button>
          </p>
        </div>
      </div>
    </Col>
  );
};

export default FileUpload;
