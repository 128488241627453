import { Fragment, useEffect, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
//includes
import StatusBar from "elements/statusBar";
import { api, common, types } from "helpers";
// elements
import ModalInfo from "elements/modalInfo";
import CoursePickup from "includes/coursePickup";
import CourseSelected from "includes/courseSelected";
// const
const htmlParse = require("html-react-parser");

function Info() {
  // props
  let { id, tId, tKey } = useParams();
  const navigate = useNavigate();
  const refOverview = useRef(null);
  const refAdmission = useRef(null);
  const refModules = useRef(null);
  const refIntake = useRef(null);
  const refCareerPath = useRef(null);
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [status, setStatus] = useState("process");
  const [studentInfoStatus, setStudentInfoStatus] = useState("process");
  const [studentInfo, setStudentInfo] = useState([]);
  const [result, setResult] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [activeTab, setActiveTab] = useState("A");
  const [tsList, setTsList] = useState([]);
  const [infoModal, setInfoModal] = useState(null);

  const activeScroll = () => {
    const sections = document.querySelectorAll("section[id]");

    window.addEventListener("scroll", function () {
      let scrollY = window.pageYOffset;
      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 70;
        let sectionId = current.getAttribute("id");

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          console.log({ sectionId });
          let status = {
            A: "courseOverview",
            B: "academicBackground",
            C: "modules",
            D: "refCareerPath",
            E: "intakeDates",
          };

          if (status[activeTab] !== sectionId) {
            console.log("true", activeTab);
            if (sectionId === "courseOverview") {
              setActiveTab("A");
            } else if (sectionId === "academicBackground") {
              setActiveTab("B");
            } else if (sectionId === "modules") {
              setActiveTab("C");
            } else if (sectionId === "refCareerPath") {
              setActiveTab("D");
            } else if (sectionId === "intakeDates") {
              setActiveTab("E");
            }
          }
        }
      });
    });
  };

  // effect
  useEffect(() => {
    getCourseInfo();
    setTimeout(() => {
      activeScroll();
    }, 2000);
  }, []);

  useEffect(() => {
    if (userInfoStatus === "success") {
      if (authInfo.role === "agent" && Number(tKey) !== 0) {
        studentProgress("init");
      }
    }
  }, [userInfoStatus]);

  // api
  const getCourseInfo = () => {
    let data = {
      url: "course",
      query: `/${id}`,
      method: "GET",
    };
    api.call(data, (res) => {
      let apiStatus = "error";
      if (res.status === 200) {
        setResult(res.data);
        apiStatus = "success";
      }
      setStatus(apiStatus);
    });
  };

  // support
  const shTestScore = (key) => {
    setTsList((prev) => {
      let nData = [...prev];
      if (nData.includes(key)) {
        nData.splice(nData.indexOf(key), 1);
      } else {
        nData.push(key);
      }
      return nData;
    });
  };

  //is applied
  const isApplied = (id) => {
    return (
      (userInfo?.course?.list.includes(id) ||
        studentInfo?.course?.list.includes(id)) &&
      tId === "p"
    );
  };

  const studentProgress = (type) => {
    let data = {
      url: "agent_student_info",
      query: `/${tKey.split(":")[0]}`,
      method: "GET",
    };
    if (authInfo.role === "agent") {
      api.call(data, (res) => {
        if (res.status === 200) {
          let result = common.reParse(res.data);
          // set pick
          result.course.list = [];
          result.course.picked.map((i) =>
            result.course["list"].push(i.course._id)
          );
          setStudentInfo(result);
          setStudentInfoStatus("success");
        } else {
          common.notify("W", "Please select the valid student");
          navigate("/agent/student/list");
        }
      });
    }
  };

  const getApplyText = () => {
    let res = "Apply Now";

    if (["ch", "cp"].includes(tId)) {
      res = "Change Now";
    } else if (["ur", "u"].includes(tId)) {
      res = "Update Now";
    } else if (tId === "s") {
      res = "Pick Now";
    }
    return res;
  };

  // course apply
  const applyCourse = (id) => {
    if (
      (authInfo.role === "agent" || authInfo.role === "sub_agent") &&
      Number(tKey) === 0
    ) {
      common.notify("I", "Please select the student");
      navigate("/agent/student/list");
    } else {
      if ((studentInfo?.progress?.total == 100 || userInfo?.progress?.total == 100)) {
        setCourseId(id);
      } else {
        common.notify(
          "I",
          "You have not completed your profile please complete and select the course"
        );
      }
    }
  };
  
  //render
  return (
    <Fragment>
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          <div className="title-wrap  course-info-title-wrap d-flex align-items-center justify-content-between pt-0 pb-0">
            <div className="d-flex align-items-center course-info-title-left">
              <img
                src={common.loadImg("back-icn.svg")}
                className="ed-cp pe-4"
                alt=""
                onClick={() => navigate(-1)}
              />
              <Link
                to={`/university/info/${result.university._id}/${tId}/${tKey}`}
                className="card-course-title-link"
              >
                <img
                  src={common.cloudImg(result.university?.logo)}
                  height={80}
                  width={80}
                  alt=""
                  className="border-left-right et-cp p-2"
                />
              </Link>
              <div>
                <Link
                  to={`/university/info/${result.university._id}/${tId}/${tKey}`}
                  className="card-course-title-link"
                >
                  <p className="inner-page-title-sm ms-4 mb-0">
                    {result.university?.name}
                  </p>
                </Link>
                <p className="inner-page-title ms-4 mb-0">{result.name}</p>
              </div>
            </div>
            <div className="d-flex align-items-center course-info-title-right">
              {/* <div>
                <span className="course-tag me-3">{result.courseLevel}</span>
                <span className="course-tag bg-gold me-3">
                  {result.courseDurationType} Year
                </span>
              </div> */}
              <div
                className={
                  isApplied(result._id)
                    ? "d-flex justify-content-between align-items-center course-card-footer course-info-head-right course-card-footer-pd applied-course-info"
                    : "d-flex justify-content-between align-items-center course-card-footer course-info-head-right course-card-footer-pd"
                }
              >
                <div className="px-4">
                  <p className="amount">
                    {/* {result.tuitionFee} {result.currency} */}
                    {common.currencyToSymbol(result.currency)}{" "}
                    {common.numberWithCommas(result.tuitionFee)}
                  </p>
                  <p className="label">Per Annum</p>
                </div>
                <div className="px-4">
                  {isApplied(result._id) ? (
                    <p className="apply-success">Applied</p>
                  ) : (
                    <p
                      className="apply-now"
                      onClick={() => applyCourse(result._id)}
                    >
                      {getApplyText()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrap" style={{ paddingTop: "34vh" }}>
            <ul className="course-nav">
              <li className={activeTab === "A" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollTo("top");
                    setActiveTab("A");
                  }}
                >
                  Overview
                </Link>
              </li>
              <li className={activeTab === "B" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollToRef(refAdmission, 260);
                    setActiveTab("B");
                  }}
                >
                  Admission
                </Link>
              </li>
              <li className={activeTab === "C" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollToRef(refModules, 260);
                    setActiveTab("C");
                  }}
                >
                  Modules
                </Link>
              </li>
              <li className={activeTab === "D" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollToRef(refModules, 260);
                    setActiveTab("D");
                  }}
                >
                  Career Path
                </Link>
              </li>

              <li className={activeTab === "E" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollTo("bottom");
                    setActiveTab("E");
                  }}
                >
                  Intake Dates
                </Link>
              </li>
            </ul>
            <div className="p-3">
              <section
                className="header-col mt-3"
                id="courseOverview"
                ref={refOverview}
              >
                <h3>Course Overview</h3>
              </section>
              <div className="content-col editor-content-wrap">
                {htmlParse(result.overview)}

                {/* <h5 className="course-type mt-4">Course Types</h5>
              <div className="course-type-list d-flex">
                {result.attendanceType.map((ctype, idx) => (
                  <span className="course-tag me-3" key={idx}>
                    {ctype}
                  </span>
                ))}
              </div> */}
                <div className="course-detail-items-wrap">
                  <div className="course-detail-items">
                    <div>
                      <img src={common.loadImg("level-icn.svg")} alt="icn" />
                    </div>
                    <div className="ps-3">
                      <p className="detail-txt">{result.courseLevel}</p>
                      <p className="detail-label">Course Level</p>
                    </div>
                  </div>
                  <div className="course-detail-items">
                    <div>
                      <img src={common.loadImg("duration-icn.svg")} alt="icn" />
                    </div>
                    <div className="ps-3">
                      <p className="detail-txt">
                        {result.courseDurationType} Year
                      </p>
                      <p className="detail-label">Course Duration</p>
                    </div>
                  </div>
                  <div className="course-detail-items">
                    <div>
                      <img src={common.loadImg("mode-icn.svg")} alt="icn" />
                    </div>
                    <div className="ps-3">
                      <p className="detail-txt">
                        {result.attendanceType.map((ctype, idx) => (
                          <span key={idx}>{ctype}</span>
                        ))}
                      </p>
                      <p className="detail-label">Mode of Study</p>
                    </div>
                  </div>
                  {result.awarded &&
                    <div className="course-detail-items">
                    <div>
                      <img src={common.loadImg("duration-icn.svg")} alt="icn" />
                    </div>
                    <div className="ps-3">
                      <p className="detail-txt">
                        {result.awarded}
                      </p>
                      <p className="detail-label">Awarded</p>
                    </div>
                  </div>
                  }
                </div>
              </div>
              <section
                className="header-col"
                id="academicBackground"
                ref={refAdmission}
              >
                <h3>Admission requirements</h3>
              </section>
              <div className="content-col">
                <p className="filter-cards-title mb-3">Academic Background</p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="course-lc">
                      <p className="head-small">
                        Minimum Level of Education Completed
                      </p>
                      <p className="head-big">{result?.educationLevel?.name}</p>
                    </div>
                  </div>
                  {types.elItems.map(
                    (i, k) =>
                      result[i.value] && (
                        <div className="col-md-6" key={k}>
                          <div className="course-lc">
                            <p className="head-small">
                              Minimum {i.label} {i.value}
                            </p>
                            <p className="head-big">
                              {i.value === "elGrade"
                                ? types.gradeValue[result[i.value]]
                                : result[i.value]}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
                <p className="filter-cards-title my-3">
                  Minimum Language Test Scores
                </p>
                <div className="row">
                  {result.exam.map((i, k) => (
                    <div className="col-md-6" key={k}>
                      <div
                        className="d-flex justify-content-between align-items-center course-inner-row head et-cp"
                        onClick={() => shTestScore(k)}
                      >
                        <p className="mb-0">{i.examType}</p>
                        <img
                          src={common.loadImg(
                            `${tsList.includes(k) ? "up" : "down"}-arrow.svg`
                          )}
                        />
                      </div>
                      <div
                        className={`course-inner-row body et-${
                          tsList.includes(k) ? "show" : "hide"
                        }`}
                      >
                        <p>{i.total}</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <label>Reading</label>
                            <p>{i.reading}</p>
                          </div>
                          <div>
                            <label>Writing</label>
                            <p>{i.writing}</p>
                          </div>
                          <div>
                            <label>Listening</label>
                            <p>{i.listing}</p>
                          </div>
                          <div>
                            <label>Speaking</label>
                            <p>{i.speaking}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {result?.otherAcademic ? (
                  <>
                    <p className="filter-cards-title mt-5 mb-3">
                      Other Requirements
                    </p>
                    <div className="editor-content-wrap mb-3">
                      {htmlParse(result?.otherAcademic)}
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <section className="header-col" id="modules" ref={refModules}>
              <h3>Modules</h3>
            </section>
            <div className="content-col">
              <ul className="row">
                {result.modules.map((i, k) => (
                  <li className="col-md-4" key={k}>
                    <div className="course-modules-wrap course-modulesstep2">
                      <div className="module-header d-flex justify-content-between align-items-center">
                        <div>
                          <h3 className="module-title">{i.title}</h3>
                          <p className="module-p">{i.hourLecture}</p>
                          <p className="module-p">{i.sessions}</p>
                        </div>
                        <div className="checkbox-gray d-flex me-0">
                          <input
                            className="me-0"
                            type="checkbox"
                            defaultChecked={true}
                          />
                        </div>
                      </div>
                      <div className="content">
                        <p
                          className="module-content-p et-cp"
                          onClick={() =>
                            setInfoModal({
                              title: i.title,
                              message: i.description,
                            })
                          }
                        >
                          {common.trimString(i.description, 100)}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <section
              className="header-col"
              id="refCareerPath"
              ref={refCareerPath}
            >
              <h3>Career Path</h3>
            </section>
            <div className="content-col">
              <div className="editor-content-wrap mb-3">
                {htmlParse(result?.carrierPath)}
              </div>
            </div>

            <section className="header-col" id="intakeDates" ref={refIntake}>
              <h3>Course Intakes</h3>
            </section>
            <div className="content-col intake-content-col">
              <div className="d-flex flex-wrap">
                {result.intake.map((i) =>
                  i.month.map((o, p) => (
                    <div className="et-course-intakes me-3 mb-3" key={p}>
                      {common.getMonthList()[o - 1].label} {i.year}
                    </div>
                  ))
                )}
              </div>
            </div>
            {/* <div className="header-col p-0" id="intakeDates" ref={refIntake}>
              <div className="row">
                <div className="tabs-p col-md-2">Intake Dates</div>
                <div className="col-md-10">
                  <div className=" intake-date-list d-flex justify-content-between align-items-center">
                    
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="content-col">
              <p>
                Qui ipsorum lingua Celtae, nostra Galli appellantur. Quisque ut
                dolor gravida, placerat libero vel, euismod. Hi omnes lingua,
                institutis, legibus inter se differunt. Qui ipsorum lingua
                Celtae, nostra Galli appellantur. Quisque ut dolor gravida,
                placerat libero vel*
              </p>
            </div> */}
            {/* <div className="course-footer">
              <div className="d-flex justify-content-between align-items-center course-bottom-wrap">
                <div className="course-bottom-wrap1 course-bottom-step2">
                  <p className="label mb-0">Per Annum</p>
                  <p className="amount mb-0">
                   
                    {common.currencyToSymbol(result.currency)}{" "}
                    {common.numberWithCommas(result.tuitionFee)}
                  </p>
                </div>
                <div className="course-bottom-wrap2">
                  <p className="course-module-header-p mb-0">
                    {common.trimString(result.feeDetail, 100)}
                  </p>
                </div>
                <div className="course-bottom-wrap3">
                  <a
                    className="apply-now"
                    onClick={() => setCourseId(result._id)}
                  >
                    {getApplyText()}
                  </a>
                </div>
              </div>
            </div> */}
            <div className="shadow-div1"></div>
            <div className="shadow-div2"></div>
          </div>
        </Fragment>
      )}

      {/* modal */}
      <CourseSelected
        tKey={tKey}
        status={studentInfoStatus}
        result={studentInfo}
        studentName={studentInfo?.profile?.name}
        onReload={() => {
          studentProgress("load");
        }}
      />
      {courseId !== null && (
        <CoursePickup
          tId={tId}
          tKey={tKey}
          courseId={courseId}
          onClose={() => {
            setCourseId(null);
          }}
          onSuccess={() => {
            setCourseId(null);
            navigate(-1);
          }}
        />
      )}
      {infoModal !== null && (
        <ModalInfo data={infoModal} onYes={() => setInfoModal(null)} />
      )}
    </Fragment>
  );
}
export default Info;
