function TermsAndConditions() {
  console.log("working");
  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            ".bold{font-weight:bold}.underline{text-decoration:underline overline}.center{text-align:center}.important{color:red}.justify{text-align:justify;text-justify:inter-word}.disc{list-style-type:disc}",
        }}
      />
      <p className="bold underline center">
        Terms and Conditions for £2000 Cashback for Students
      </p>
      <br />
      <p className="justify">
        This Cashback scheme is organised and managed exclusively by Novelty App
        in collaboration with Pick-a-Uni Limited* and Uni and Student Limited*.
        It is not affiliated with any of the partnered universities. By
        participating in this promotion, you agree that you will not hold any
        partnered universities responsible for any claims, damages, or
        liabilities resulting from your participation.
      </p>
      <p className="justify">
        Furthermore, the partnered universities do not endorse, sponsor, or
        administer this promotion in any way, and any questions or concerns
        regarding the promotion should be directed to Novelty-App. By
        participating in this promotion, you agree to be bound by these terms
        and conditions and the decisions of Novelty-App which are final and
        binding in all respects.
      </p>
      <p className="justify">
        By participating in the Novelty App’s promotion, you agree to be bound
        by the following terms and conditions:
      </p>
      <br />
      <ol type={1}>
        <li className="bold">Eligibility</li>
        <p className="justify">
          To qualify for the Cashback offer, you must be a student who applied
          for one of our partnered universities via the Novelty App, and have
          been accepted and enrolled in a course of study. In addition, you must
          meet the following conditions:
        </p>
        <ol type="a">
          <li className="justify">
            You need to be a resident of the United Kingdom.
          </li>
          <li className="justify">You must be at least 18 years old.</li>
          <li className="justify">
            You cannot be an employee, immediate family member of an employee,
            or agent of Novelty App or any of its affiliated companies.
          </li>
        </ol>
        <br />
        <li className="bold">Payment of breakdown</li>
        <p className="justify">
          The cashback is paid in nine instalments over a period of up to three
          years. This promotion is only available for listed Universities in the
          Novelty App. By participating in this promotion, you agree that you
          will not hold any partnered universities responsible for any claims,
          damages, or liabilities resulting from your participation.
        </p>
        <p>The payment structure is as follows:</p>
        <br />
        <p className="bold">Payment breakdown for Year 1 Cashback £1000:</p>
        <ul>
          <li className="justify disc">
            The first instalment of 25% of the £1000 cashback, equal to £250,
            will be available for students who have received their student
            finance Tuition fee and have started their Term 1 confirmed by the
            partnered University.
          </li>
          <li className="justify disc">
            The second instalment of 25% of the £1000 cashback, equal to £250,
            will be available for students who have received their student
            finance tuition and have progressed to Term 2 of their degree
            programme confirmed by the partnered University.
          </li>
          <li className="justify disc">
            The final instalment of 50% of the £1000 cashback, equal to £500,
            will be available for students who have received their student
            finance tuition and have progressed to Term 3 of their degree
            programme confirmed by the partnered University.
          </li>
        </ul>
        <br />
        <p className="bold">Payment breakdown for Year 2 Cashback £500:</p>
        <ul>
          <li className="justify disc">
            The first instalment of 25% of the £500 cashback, equal to £125,
            will be available for students who have received their student
            finance Tuition and have progressed to Term 4 of their degree
            programme confirmed by the partnered University.
          </li>
          <li className="justify disc">
            The second instalment of 25% of the £500 cashback, equal to £250,
            will be available for students who have received their student
            finance Tuition and have progressed to Term 5 of their degree
            programme confirmed by the partnered University.
          </li>
          <li className="justify disc">
            The final instalment of 50% of the £500 cashback, equal to £500,
            will be available for students who have received their student
            finance Tuition and have progressed to Term 6 of their degree
            programme confirmed by the partnered University.
          </li>
        </ul>
        <br />
        <p className="bold">Payment breakdown for Year 3 Cashback £500:</p>
        <ul>
          <li className="justify disc">
            The first instalment of 25% of the £500 cashback, equal to £125,
            will be available for students who have received their student
            finance Tuition and have progressed to Term 7 of their degree
            programme confirmed by the partnered University.
          </li>
          <li className="justify disc">
            The second instalment of 25% of the £500 cashback, equal to £250,
            will be available for students who have received their student
            finance Tuition and have progressed to Term 8 of their degree
            programme confirmed by the partnered University.
          </li>
          <li className="justify disc">
            The final instalment of 50% of the £500 cashback, equal to £500,
            will be available for students who have received their student
            finance Tuition and have progressed to Term 9 of their degree
            programme confirmed by the partnered University.
          </li>
        </ul>
        <br />
        <li className="bold">Limitations</li>
        <ol type="a">
          <li className="justify">
            The Cashback is limited to one per person.
          </li>
          <li className="justify">
            Any multiple accounts created by the same person under other aliases
            will be disqualified, and the individual will be denied all future
            promotions from the company.
          </li>
          <li className="justify">
            Any acts of foul play will also be disqualified, and the individual
            will be denied all future promotions from the company.
          </li>
          <li className="justify">
            The disbursement of the cashback amount is subject to verifying the
            student's eligibility based on the aforementioned criteria in
            section 1: Eligibility.
          </li>
          <li className="justify">
            The decision regarding the student's eligibility for the cashback
            amount lies solely with the partnered University and shall be final
            and binding.
          </li>
          <li className="justify">
            The cashback amount will be disbursed to the eligible students
            within the stipulated time frame specified by the partnered
            University.
          </li>
          <li className="justify">
            The disbursement of the cashback amount is subject to the
            availability of funds and may be subject to change without prior
            notice.
          </li>
          <li className="justify">
            The cashback amount cannot be combined with any other offer or
            promotion offered by the partnered University or any third-party
            provider.
          </li>
          <li className="justify">
            The terms and conditions regarding the disbursement of the cashback
            amount are subject to change without prior notice, and the students
            are advised to check the updated terms and conditions from time to
            time.
          </li>
        </ol>
        <br />
        <li className="bold">Termination and Modification</li>
        <p className="justify">
          Novelty App reserves the right to terminate or modify the promotion at
          any time, without prior notice, and for any reason.
        </p>
        <br />
        <li className="bold">Disclaimer</li>
        <p className="justify">
          Novelty App is not responsible for any technical problems, human
          error, or other issues that may prevent you from participating in the
          promotion or receiving the cashback. Novelty App is also not
          responsible for any injury, loss, or damage that may occur as a result
          of your participation in the promotion.
        </p>
        <br />
        <li className="bold">Governing Law</li>
        <p className="justify">
          These terms and conditions are governed by and construed in accordance
          with the laws of the United Kingdom.
        </p>
        <p className="justify">
          By participating in the promotion, you agree to be bound by these
          terms and conditions. If you do not agree with any of the terms and
          conditions, please do not participate in the promotion.
        </p>
      </ol>
      <br />
      <p className="justify">
        <span className="important">*</span>
        1. "Uni and Student Limited" is a company based in the UK and Wales with
        the registration number 13906680.
      </p>
      <p className="justify">
        <span className="important">*</span>
        2. "Pick-a-Uni Limited" is a company based in the UK and Wales with the
        registration number 14680734.
      </p>
    </div>
  );
}

export default TermsAndConditions;
