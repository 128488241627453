import MenuIcon from "@mui/icons-material/Menu";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
// import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate } from "react-router-dom";

// includes
import { api, common, types } from "helpers";
import { useEffect, useState } from "react";
import StatusBar from "../elements/statusBar";

const InnerHeader = (props) => {
  const menuClick = () => {
    const toggle = document.querySelector("[aria-label = 'Toggle navigation']");
    toggle.click();
  };

  const { handleDrawer, socket } = props;
  const [notify, setNotify] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [readCount, setReadCount] = useState(0);
  const [pg, setPg] = useState(1);
  const authInfo = common.getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    notification();
  }, []);

  //socket
  useEffect(() => {
    socket.on(`${types?.socket?.APPLICATION_TIMELINE}`, (data) => {
      if (data?.userID === common.getAuth()?._id) {
        notification();
      }
    });
  }, [socket]);

  const nav = (readId, type, attr = "") => {
    if (type === "application") {
      notificationMakeRead(readId);
      navigate(`/application/status/${attr}`);
    }
  };

  const notificationMakeRead = (readId) => {
    let data = {
      url: "notification",
      method: "PUT",
      query: `/read/${readId}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        notification();
      }
    });
  };

  const notification = (page = 1) => {
    setIsLoading(true);
    let data = {
      url: "notification",
      method: "GET",
      query: `?offset=${page}&limit=5`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        if (page === 1) {
          setReadCount(result?.pageInfo?.readCount);
          setNotify(result.data);
          setHasMore(result?.pageInfo?.totalCount > result?.data?.length);
        } else {
          setPg(page);
          setNotify((prev) => [...prev, ...result.data]);
          let total = [...notify, ...result.data];
          setHasMore(result?.pageInfo?.totalCount > total);
        }
      } else {
        //error
      }
      setIsLoading(false);
    });
  };

  // functions
  const onLogout = (e) => {
    e.preventDefault();
    let data = authInfo;
    common.localRemove("authData");
    if (data.role == "student") {
      document.cookie =
        "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.pickauni.com; path=/;";
      document.cookie =
        "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.pickauni.international; path=/;";
      setTimeout(() => {
        window.location.href = "https://www.pickauni.com/auth/student";
      }, 600);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Navbar className="header1-wrap" expand="lg" sticky="top">
        <Container fluid className="p-0">
          <Navbar.Brand>
            <Link to="#" className="text-dec-none">
              {/* <img src={common.cloudImg("media/logo.svg")} /> */}
              <img
                src={common.loadImg("pickauni-logo-white.png")}
                alt="logo"
                className="header-logo-img"
              />
            </Link>
          </Navbar.Brand>
          <button className="btn btn-toggle" onClick={handleDrawer}>
            <MenuIcon />
          </button>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            id="navbarScroll"
            className="justify-content-end et-desktop-nav"
          >
            <Nav
              className="justify-content-end border-nav-item"
              activeKey="/home"
            >
              <Nav.Item className="nav-padding notify-icon-wrap">
                {/* <Nav.Link href="">
                  <img
                    src={common.loadImg("notification-bell.svg")}
                    className=""
                  ></img>
                </Nav.Link> */}
                <span className="notify-count">{readCount}</span>
                <NavDropdown
                  align="end"
                  title={
                    <span>
                      <img src={common.loadImg("notification-bell.svg")}></img>
                    </span>
                  }
                  className="notification-drop-menu"
                  id="collasible-nav-dropdown"
                >
                  <div id="scrollBottom">
                    {notify?.length ? (
                      <div>
                        <InfiniteScroll
                          next={() => {
                            notification(pg + 1);
                          }}
                          style={{
                            height: "auto",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column-reverse",
                          }}
                          hasMore={hasMore}
                          height={380}
                          inverse={true}
                          loader={
                            isLoading ? <StatusBar status="process" /> : null
                          }
                          dataLength={notify?.length}
                          initialScrollY="200px"
                        >
                          {notify.map((item) => (
                            <NavDropdown.Item
                              onClick={() =>
                                nav(item?._id, item?.type, item?.id1)
                              }
                            >
                              <div className="tab-notify-header-wrap">
                                <div
                                  className={
                                    item?.read
                                      ? "tab-notify-wrap-read et-cp"
                                      : "tab-notify-wrap-unread et-cp"
                                  }
                                >
                                  <div>
                                    <div className="tab-msg-wrap">
                                      <img
                                        src={common.loadImg("tab-msg-icn.svg")}
                                        alt="user-icon"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <p className="tab-notify-txt tab-header-notify-txt">
                                      {item.description}
                                    </p>
                                    <label className="tab-notify-time">
                                      {common.timeSince(
                                        new Date(item.createdAt)
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </NavDropdown.Item>
                          ))}
                        </InfiniteScroll>
                      </div>
                    ) : (
                      <NavDropdown.Item>
                        <div className="tab-notify-header-wrap">
                          <p className="tab-notify-txt tab-header-notify-txt">
                            No record found
                          </p>
                        </div>
                      </NavDropdown.Item>
                    )}
                  </div>
                </NavDropdown>
              </Nav.Item>

              <Nav.Item className="nav-padding">
                <Nav.Link eventKey="link-1">
                  <span className="form-label me-3">Contact us</span>
                  <img src={common.loadImg("message-icn.svg")}></img>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav2-padding position-relative">
                <Nav.Link eventKey="link-2">
                  <div className="d-flex align-items-center border-disable">
                    <span>
                      <h6 className="d-flex justify-content-start header1-welcome p-0 mb-0">
                        Welcome
                      </h6>
                      <p className="d-flex justify-content-start profile-name-text p-0 me-2 mb-0">
                        {`${authInfo?.firstName}`}
                      </p>
                    </span>
                    <img
                      src={common.loadImg("user-icon.svg")}
                      alt="userimage"
                    />
                  </div>
                </Nav.Link>
                {/* <span> */}
                <NavDropdown
                  align="end"
                  title={
                    <span>
                      <img src={common.loadImg("down-arrow.svg")}></img>
                    </span>
                  }
                  id="collasible-nav-dropdown"
                  className="custom-arrow-dropdown"
                >
                  {/* <NavDropdown.Item
                    onClick={() =>
                      navigate(
                        authInfo.role === "student"
                          ? "/student/profile"
                          : "/agent/account/info"
                      )
                    }
                  >
                    <span className="d-flex card-course-title-link et-cp">
                      My Account
                    </span>
                  </NavDropdown.Item> */}
                  <NavDropdown.Item onClick={() => navigate("/settings")}>
                    <span className="d-flex card-course-title-link et-cp">
                      Settings
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onLogout}>
                    <span className="d-flex card-course-title-link et-cp">
                      Logout
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>
                {/* </span> */}
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>

        <Navbar.Collapse
          id="navbarScroll"
          className="et-res-nav-wrap"
          onClick={menuClick}
        >
          <Nav className="justify-content-end" activeKey="/home">
            {types.menus[authInfo.role].map((i, k) => (
              <Nav.Item className="p-3" key={k}>
                <Link className="nav-link ps-3" to={i.url}>
                  <img src={common.loadImg(i.icon)} alt="" />
                  <span className="form-label ms-3">{i.name}</span>
                </Link>
              </Nav.Item>
            ))}
            <Nav.Item className="p-3">
              <Nav.Link eventKey="link-1" className="ps-3">
                <img src={common.loadImg("message-icn.svg")}></img>
                <span className="form-label ms-3">Contact us</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="p-3">
              <Nav.Link eventKey="link-1" className="ps-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <img src={common.loadImg("user-icon.svg")}></img>
                    <span className="form-label ms-3">{`${authInfo?.firstName}`}</span>
                  </div>
                  <button className="btn btn-logout me-3" onClick={onLogout}>
                    Logout
                  </button>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default InnerHeader;
