import React, { useEffect, useState } from "react";
import { common } from "helpers";

const AlertBar = (props) => {
  const [show, setShow] = useState(true);
  return (
    show &&
    (props.status == "success" ? (
      <div className="alert-msg success d-flex align-items-center mb-4">
        <img
          src={common.loadImg("close-icn.svg")}
          alt="alert-tyle"
          className="me-2 cp"
          onClick={() => setShow(false)}
        />
        <label>{props.message}</label>
      </div>
    ) : props.status == "warning" ? (
      <div className="alert-msg warning d-flex align-items-center mb-4">
        <img
          src={common.loadImg("close-icn.svg")}
          alt="alert-tyle"
          className="me-2 cp"
          onClick={() => setShow(false)}
        />
        <label>{props.message}</label>
      </div>
    ) : props.status == "info" ? (
      <div className="alert-msg info d-flex align-items-center mb-4">
        <img
          src={common.loadImg("close-icn.svg")}
          alt="alert-tyle"
          className="me-2 cp"
          onClick={() => setShow(false)}
        />
        <label>{props.message}</label>
      </div>
    ) : (
      props.status == "error" && (
        <div className="alert-msg error d-flex align-items-center mb-4">
          <img
            src={common.loadImg("close-icn.svg")}
            alt="alert-tyle"
            className="me-2 cp"
            onClick={() => setShow(false)}
          />
          <label>{props.message}</label>
        </div>
      )
    ))
  );
};

export default AlertBar;
