import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Fragment, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Select from "react-select";
// includes
import StatusBar from "elements/statusBar";
import { api, common } from "helpers";
// elements
import ModalInfo from "elements/modalInfo";
import CoursePickup from "includes/coursePickup";
import CourseSelected from "includes/courseSelected";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "40px",
    height: "40px",
    borderRadius: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "40px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    marginTop: "-12px",
  }),
};

function Finder() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ref
  let { tId, tKey } = useParams();
  let search = useLocation().search;
  const navigate = useNavigate();
  const couseRef = useRef(null);
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();

  //  common.urlQueryToJson(search)

  // state
  const [init, setInit] = useState(false);
  const [status, setStatus] = useState("process");
  const [scrollEnd, setScrollEnd] = useState(false);
  const [scrollTotal, setScrollTotal] = useState(0);
  const [filterFields] = useState({
    search: "",
    university: [],
    courseLevel: [],
    courseDurationType: "",
    tuitionFee: [1, 80000],
    country: "GB",
    province: [],
    attendanceType: [],
    intakeYear: "",
    intakeMonth: [],
    subject: [],
    examType: "",
    reading: "",
    writing: "",
    speaking: "",
    listening: "",
    educationLevel: "",
    educationCountry: "",
    gradeType: "",
    gradeScore: "",
    nationality: [],
    visaPermit: "",
    promotionCode: "",
  });
  const [pagingFields] = useState({ limit: 9, offset: 1 });
  const [filter, setFilter] = useState(common.reParse(filterFields));
  const [paging, setPaging] = useState(common.reParse(pagingFields));
  const [gradeScheme, setGradeScheme] = useState([]);
  const [sorting, setSorting] = useState({
    sortBy: "tuitionFee",
    sortDir: "asc",
  });
  const [courseId, setCourseId] = useState(null);
  const [result, setResult] = useState([]);
  const [studentInfoStatus, setStudentInfoStatus] = useState("process");
  const [studentInfo, setStudentInfo] = useState([]);
  const [data, setData] = useState({
    university: [],
    courseLevel: ["Bachelor", "Master", "Foundation", "Doctorate"],
    courseLength: [
      { label: "Less than 1 year", value: 1 },
      { label: "1-2 years", value: 2 },
      { label: "2-3 years", value: 3 },
      { label: "4-5 years", value: 4 },
      { label: "More than 5 years", value: 5 },
    ],
    courseFees: [0, 100000],
    country: [
      // { label: "United States", value: "US" },
      // { label: "Australia", value: "AU" },
      { label: "Ireland", value: "IE" },
      // { label: "China", value: "CN" },
      // { label: "Canada", value: "CA" },
      { label: "United kingdom", value: "GB" },
    ],

    province: [],
    modeOfStudy: ["Fulltime", "Distance", "Online", "Blended"],
    IntakeYear: common.getFullYear(3),
    IntakeMonth: common.getMonthList(),
    subject: [],
    examType: ["IELTS", "TOEFL", "IELTS UKVI"],
    examLevel: ["Highschool", "Undergraduate", "Postgraduate", "Diploma"],
    educationLevel: [
      { label: "Grade 12 / High School", value: "highschool" },
      { label: "2-Year e Diploma", value: "diploma" },
      { label: "3-Year Diploma", value: "advanced_diploma" },
      { label: "3-Year Bachelors Degree", value: "3_bachelor" },
      { label: "3-Year Bachelors Degree", value: "bachelor" },
      { label: "Master", value: "masters" },
      { label: "Doctoral Degree", value: "doctoral" },
    ],
    educationCountry: [
      { label: "United kingdom", value: "GB" },
      { label: "Sri Lanka", value: "LK" },
      { label: "India", value: "IN" },
      { label: "Bangladesh", value: "BD" },
    ],
    gradeScheme: [],
    nationality: [],
  });
  const [selected, setSelected] = useState({
    university: [],
    subject: [],
    province: [],
    intakeMonth: [],
    nationality: [],
  });
  const [loading, setLoading] = useState({
    university: false,
    subject: false,
    province: false,
    nationality: false,
    loadMore: false,
    applyNow: false,
  });
  const [infoModal, setInfoModal] = useState(null);

  // effect
  useEffect(() => {
    if (search) {
      let jParams = common.urlQueryToJson(search);
      setFilter((oldState) => ({
        ...oldState,
        university: jParams?.uid ? [jParams?.uid] : [],
      }));
      setSelected((oldState) => ({
        ...oldState,
        university: jParams?.uid
          ? [{ label: decodeURI(jParams?.uName), value: jParams?.uid }]
          : [],
      }));
    }
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (userInfoStatus === "success") {
      if (
        (authInfo.role === "agent" || authInfo.role === "sub_agent") &&
        Number(tKey) !== 0
      ) {
        studentProgress("init");
      } else {
        roleInitList();
      }
    }
  }, [userInfoStatus]);

  useEffect(() => {
    if (init) {
      getList();
    }
  }, [paging]);

  useEffect(() => {
    if (filter?.educationLevel && filter?.educationCountry) {
      getGradeList(filter?.educationCountry, filter?.educationLevel);
    }
    if (init) {
      const timeOutId = setTimeout(() => {
        setPaging(common.reParse(pagingFields));
      }, 50);
      return () => clearTimeout(timeOutId);
    }
  }, [filter]);

  useEffect(() => {
    if (scrollEnd) {
      handlePageChange();
    }
  }, [scrollEnd]);

  // api
  const roleInitList = () => {
    getList();
    getInitlist();
    setInit(true);
    getStateList(filterFields?.country);
  };

  const getInitlist = () => {
    getUniversityList();
    getCountryList();
    getSubjectList();
  };

  const getList = () => {
    if (!loading.loadMore) {
      setStatus("process");
    }
    // api call
    let query = `?query=${JSON.stringify(filter)}&isView=${
      ["agent", "sub_agent"].includes(authInfo.role)
        ? "Agent"
        : authInfo.role == "student"
        ? "Student"
        : "Both"
    }&limit=${paging.limit}&offset=${paging.offset}&sortBy=${
      sorting.sortBy
    }&sortDir=${sorting.sortDir}`;
    let data = {
      url: "course",
      method: "GET",
      query,
    };
    api.call(data, (res) => {
      let status = "error";
      if (res.status === 200) {
        status = "success";
        if (!loading.loadMore) {
          setResult(res.data);
          if (res.data?.data.length === 0) {
            status = "empty";
          }

          if (res?.data?.pageInfo?.maxPrice) {
            setData((prv) => ({
              ...prv,
              courseFees: [0, res?.data?.pageInfo?.maxPrice],
            }));
          }
        } else {
          const oldData = common.reParse(result);
          const resultData = res.data;
          resultData.data = oldData.data.concat(resultData.data);
          setResult(resultData);
          loader("loadMore", false);
          if (res?.data?.pageInfo?.maxPrice) {
            setData((prv) => ({
              ...prv,
              courseFees: [0, res?.data?.pageInfo?.maxPrice],
            }));
          }
        }
      }
      setStatus(status);
    });
  };

  const getUniversityList = () => {
    let query = `?isView=${
      ["agent", "sub_agent"].includes(authInfo.role)
        ? "Agent"
        : authInfo.role == "student"
        ? "Student"
        : "Both"
    }&limit=1000&offset=0`;
    let data = {
      url: "university",
      method: "GET",
      query,
    };
    api.call(data, (res) => {
      if (res?.data?.data) {
        setData((oldData) => {
          let newData = common.reParse(oldData);
          newData.university = common.SetMultiSelect(
            res.data.data,
            "name",
            "_id"
          );
          return newData;
        });
      }
      loader("university", false);
    });
  };

  const getSubjectList = () => {
    let query = `?limit=1000&offset=0`;
    let data = {
      url: "subject",
      method: "GET",
      query,
    };
    api.call(data, (res) => {
      if (res?.data?.data) {
        setData((oldData) => {
          let newData = common.reParse(oldData);
          newData.subject = common.SetMultiSelect(res.data.data, "name", "_id");
          return newData;
        });
      }
      loader("subjects", false);
    });
  };

  const getStateList = (id) => {
    if (id == "") {
      setData((oldData) => {
        let newData = common.reParse(oldData);
        newData.province = [];
        return newData;
      });
    } else {
      loader("province", true);
      // let queryParms = { countryId: id };
      let query = `/${id}`;
      let data = {
        url: "common_state",
        method: "GET",
        query,
      };
      api.call(data, (res) => {
        if (res?.data?.data) {
          setData((oldData) => {
            let newData = common.reParse(oldData);
            newData.province = common.SetMultiSelect(
              res.data.data,
              "name",
              "_id"
            );
            return newData;
          });
        }
        loader("province", false);
      });
    }
  };

  const getCountryList = () => {
    let query = `?limit=1000&offset=0`;
    let data = {
      url: "common_country",
      method: "GET",
      query,
    };
    api.call(data, (res) => {
      if (res?.data?.data) {
        setData((oldData) => {
          let newData = common.reParse(oldData);
          newData.nationality = common.SetMultiSelect(
            res.data.data,
            "name",
            "_id"
          );
          return newData;
        });
      }
      loader("nationality", false);
    });
  };

  const getGradeList = (country, educationLevel) => {
    let data = {
      url: "common_grade",
      method: "GET",
      query: `/${country}/${educationLevel}`,
    };
    api.call(data, (res) => {
      if (res?.data?.length) {
        setData((oldData) => {
          let newData = common.reParse(oldData);
          newData.gradeScheme = common.SetMultiSelect(res.data, "name", "_id");
          return newData;
        });
        setGradeScheme(res?.data);
      } else {
        setGradeScheme([]);
        setData((oldData) => {
          let newData = common.reParse(oldData);
          newData.gradeScheme = [];
          return newData;
        });
      }
      // loader("nationality", false);
    });
  };

  const studentProgress = (type) => {
    let data = {
      url: "agent_student_info",
      query: `/${tKey.split(":")[0]}`,
      method: "GET",
    };
    if (authInfo.role === "agent" || authInfo.role === "sub_agent") {
      api.call(data, (res) => {
        if (res.status === 200) {
          let result = common.reParse(res.data);
          // set pick
          result.course.list = [];
          result.course.picked.map((i) =>
            result.course["list"].push(i.course._id)
          );
          setStudentInfo(result);
          setStudentInfoStatus("success");
          if (type === "init") {
            roleInitList();
          }
        } else {
          common.notify("W", "Please select the valid student");
          navigate("/agent/student/list");
        }
      });
    }
  };

  // handler
  const handleOnFilter = (e) => {
    const { name, value } = e.target;
    if (name == "sorting") {
      setSorting({ sortBy: value.split(":")[0], sortDir: value.split(":")[1] });
      setPaging(common.reParse(pagingFields));
    }
  };

  const handleOnFilterReset = (e) => {
    //console.log({filterFields})
    setFilter(common.reParse(filterFields));
    setSelected({
      university: [],
      subject: [],
      province: [],
      intakeMonth: [],
      nationality: [],
    });
  };

  const handlePageChange = () => {
    if (
      result?.pageInfo?.totalCount > result?.data?.length &&
      !loading.loadMore
    ) {
      loader("loadMore", true);
      setPaging({ ...paging, offset: paging.offset + 1 });
    }
  };

  const loader = (name, status) => {
    setLoading({ ...loading, [name]: status });
  };

  // Checkbox Change
  const onCheckChange = (e) => {
    const { name, checked, value } = e.target;
    setFilter((oldData) => {
      let newData = common.reParse(oldData);
      if (checked) {
        newData[name].push(value);
      } else {
        newData[name].splice(newData[name].indexOf(value), 1);
      }
      return newData;
    });
  };

  const onSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === "examType") {
      setFilter({
        ...filter,
        examType: value,
        reading: "",
        writing: "",
        speaking: "",
        listening: "",
      });
    } else {
      setFilter({ ...filter, [name]: value });
      if (name == "country") {
        getStateList(value);
      }
    }
  };

  const onReactSelectChange = (name, value) => {
    let resultList = [];
    value.map((i, k) => {
      resultList.push(i.value);
    });
    setFilter({ ...filter, [name]: resultList });
    setSelected({ ...selected, [name]: value });
  };

  const onUniversitySelectChange = (val) => {
    if (val.length) {
      setFilter((prev) => {
        let nData = common.reParse(prev);
        nData.university.push(val[0].value);
        return nData;
      });
      setSelected((prev) => {
        let nData = common.reParse(prev);
        nData.university.push(val[0]);
        return nData;
      });
    }
  };

  const onSubjectSelectChange = (val) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.subject.push(val[0].value);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.subject.push(val[0]);
      return nData;
    });
  };

  const onProvinceSelectChange = (val) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.province.push(val[0].value);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.province.push(val[0]);
      return nData;
    });
  };
  const onIntakeMonthSelectChange = (val) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.intakeMonth.push(val[0].value);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.intakeMonth.push(val[0]);
      return nData;
    });
  };
  const onNationalitySelectChange = (val) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.nationality.push(val[0].value);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.nationality.push(val[0]);
      return nData;
    });
  };

  const onIntakeMonthSelectRemove = (key) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.intakeMonth.splice(key, 1);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.intakeMonth.splice(key, 1);
      return nData;
    });
  };

  const onProvinceSelectRemove = (key) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.province.splice(key, 1);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.province.splice(key, 1);
      return nData;
    });
  };

  const onUniversitySelectRemove = (key) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.university.splice(key, 1);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.university.splice(key, 1);
      return nData;
    });
  };
  const onSubjectSelectRemove = (key) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.subject.splice(key, 1);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.subject.splice(key, 1);
      return nData;
    });
  };
  const onNationalitySelectRemove = (key) => {
    setFilter((prev) => {
      let nData = common.reParse(prev);
      nData.nationality.splice(key, 1);
      return nData;
    });
    setSelected((prev) => {
      let nData = common.reParse(prev);
      nData.nationality.splice(key, 1);
      return nData;
    });
  };

  const onInputExamChange = (e) => {
    const { name, value } = e.target;
    let newValue = validateExam(value);
    if (newValue !== filter[name]) {
      setFilter({ ...filter, [name]: newValue });
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const onSliderChange = (event, newValue) => {
    setFilter({ ...filter, tuitionFee: newValue });
  };

  const validateExam = (val) => {
    let resultList = 0;
    let points = 0;
    if (filter.examType === "TOEFL") {
      points = 30;
      resultList = common.onlyNumbers(val);
    } else {
      points = 9;
      resultList = common.onlyFloat(val);
    }
    if (parseFloat(resultList) > points) {
      resultList = points;
    }
    return resultList;
  };

  // course apply
  const applyCourse = (id) => {
    if (
      (authInfo.role === "agent" || authInfo.role === "sub_agent") &&
      Number(tKey) === 0
    ) {
      common.notify("I", "Please select the student");
      navigate("/agent/student/list");
    } else {
      if (
        studentInfo?.progress?.total == 100 ||
        userInfo?.progress?.total == 100
      ) {
        setCourseId(id);
      } else {
        common.notify(
          "I",
          "You have not completed your profile please complete and select the course"
        );
      }
    }
  };

  const isApplied = (id) => {
    return (
      (userInfo?.course?.list.includes(id) ||
        studentInfo?.course?.list.includes(id)) &&
      tId === "p"
    );
  };

  // support
  const getApplyText = () => {
    let res = "Apply Now";
    if (["ch", "cp"].includes(tId)) {
      res = "Change Now";
    } else if (["ur", "u"].includes(tId)) {
      res = "Update Now";
    } else if (tId === "s") {
      res = "Pick Now";
    }
    return res;
  };

  // load more scroll
  const onScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled >= 1) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const offerUI = (item) => {
    const { university } = item;

    if (university?.cab && university?.cbCy && authInfo?.role == "student") {
      return (
        <li className="green mb-2">
          <img
            src={common.loadImg("cashback_icon_1.svg")}
            alt="cashback-icon"
            className="cc-header-icn"
          />
          {common.currencyToSymbol(university?.cbCy)}
          {university?.cab} Cashback
        </li>
      );
    }

    if (university?.agcyCom && university?.agcy && authInfo?.role == "agent") {
      return (
        <li className="green mb-2">
          <img
            src={common.loadImg("cashback_icon_1.svg")}
            alt="cashback-icon"
            className="cc-header-icn"
          />
          {university?.agcyCom == "Percentage"
            ? ` ${university?.agcy}% Commission`
            : `${common.currencyToSymbol(university?.agcyCom)} ${
                university?.agcy
              } Commission`}
        </li>
      );
    }
    return null;
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap sticky-top course-finder-header">
        <div className="w-100">
          <div className="finder-search-wrap">
            <div>
              <h3 className="inner-page-header-title mb-0">
                {studentInfoStatus !== "process" ? (
                  <img
                    src={common.loadImg("back-icn.svg")}
                    className="ed-cp pe-4"
                    alt=""
                    onClick={() => navigate("/agent/student/list")}
                  />
                ) : null}{" "}
                Course Finder
              </h3>
            </div>

            <div className="course-srch-filter">
              {studentInfoStatus !== "process" && (
                <div className="selected-std-wrap">
                  <p className="selected-std-label">Selected Student</p>
                  <h3 className="selected-std-name">
                    {studentInfo?.profile?.name}
                  </h3>
                </div>
              )}

              <Form
                className={
                  studentInfoStatus !== "process"
                    ? "course-filter-search mt-2"
                    : "course-filter-search mt-2 w-100"
                }
              >
                <div className="course-search-wrap1 position-relative">
                  <input
                    type="text"
                    name="search"
                    className="course-right-header-input"
                    placeholder="Search University, Courses or Subject"
                    value={filter.search}
                    onChange={onInputChange}
                  />
                  <img
                    src={common.loadImg("search-icn.svg")}
                    className="course-search-icn"
                    alt=""
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wrap course-finder-page-wrap">
        <Container fluid>
          {true === false && (
            <div
              className="alert alert-info alert-dismissible fade show"
              role="alert"
            >
              <strong>Holy guacamole!</strong> You should check in on some of
              those fields below.
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          <Row>
            <Col md={3}>
              <div
                className={isActive ? "filter-col" : "filter-col filter-height"}
                id="filter-col"
              >
                <div className="d-flex justify-content-between align-items-center px-2 filter-clear-wrap">
                  {isMobile ? (
                    <p className="ed-cp" onClick={toggleClass}>
                      <span className="filter-txt ps-2">Filter</span>
                      <span className="ps-2">&#10095;</span>
                    </p>
                  ) : (
                    <p className="ed-cp">
                      {/* <img src={common.loadImg("filter-icn.svg")} alt="" />{" "} */}
                      <span className="ps-2">Filter</span>
                    </p>
                  )}
                  <p className="clear" onClick={handleOnFilterReset}>
                    Clear All
                  </p>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Promotion Code</p>
                  <input
                    type="text"
                    className="form-control form-control1"
                    placeholder="Enter promotion code"
                    name="promotionCode"
                    value={filter.promotionCode}
                    onChange={onInputChange}
                  />
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Select University</p>
                  <Select
                    menuPortalTarget={document.getElementById("filter-col")}
                    menuPosition={"fixed"}
                    defaultValue={[]}
                    value={[]}
                    isMulti
                    options={data.university.filter(
                      (i) => !filter.university.includes(i.value)
                    )}
                    placeholder=""
                    styles={customStyles}
                    className="basic-multi-select"
                    classNamePrefix="Loading..."
                    isClearable={true}
                    loading={loading.university}
                    onChange={(data) => onUniversitySelectChange(data)}
                  />
                  {selected.university.length > 0 && (
                    <ul className="selected-items">
                      {selected.university.map((i, k) => (
                        <li>
                          <span className="filter-selected-items">
                            {i.label}{" "}
                          </span>
                          <button onClick={() => onUniversitySelectRemove(k)}>
                            x
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Course Type</p>
                  <div className="filter-cards">
                    {data.courseLevel.map((item, k) => (
                      <div
                        className="filter-list d-flex justify-content-between align-items-center"
                        key={k}
                      >
                        <p>{item}</p>
                        <p>
                          <input
                            type="checkbox"
                            className="chkbox-square"
                            name="courseLevel"
                            value={item}
                            checked={filter.courseLevel.includes(item)}
                            onChange={onCheckChange}
                          />
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Course Length</p>
                  <div>
                    <Form>
                      <Form.Select
                        name="courseDurationType"
                        onChange={onSelectChange}
                        value={filter.courseDurationType}
                      >
                        <option value="">All</option>
                        {data.courseLength.map((item, k) => (
                          <option value={item.value} key={k}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form>
                  </div>
                </div>
                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Course Fee</p>

                  <Box className="score-slider">
                    <Slider
                      getAriaLabel={() => "Course Fee"}
                      valueLabelDisplay="auto"
                      value={filter.tuitionFee}
                      min={data.courseFees[0]}
                      max={data.courseFees[1]}
                      onChange={onSliderChange}
                    />
                  </Box>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Study Destination</p>
                  <Form>
                    <Form.Select
                      name="country"
                      onChange={onSelectChange}
                      value={filter.country}
                    >
                      <option value="">All</option>
                      {data.country.map((item, k) => (
                        <option value={item.value} key={k}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Study State/Province</p>

                  <Select
                    menuPortalTarget={document.getElementById("filter-col")}
                    menuPosition={"fixed"}
                    defaultValue={[]}
                    value={[]}
                    isMulti
                    options={data.province.filter(
                      (i) => !filter.province.includes(i.value)
                    )}
                    placeholder=""
                    styles={customStyles}
                    className="basic-multi-select"
                    classNamePrefix="Loading..."
                    isClearable={true}
                    loading={loading.province}
                    onChange={(data) => onProvinceSelectChange(data)}
                  />
                  {selected.province.length > 0 && (
                    <ul className="selected-items">
                      {selected.province.map((i, k) => (
                        <li>
                          <span className="filter-selected-items">
                            {i.label}{" "}
                          </span>
                          <button onClick={() => onProvinceSelectRemove(k)}>
                            x
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Mode of Study</p>
                  <div className="filter-cards">
                    {data.modeOfStudy.map((item, k) => (
                      <div
                        className="filter-list d-flex justify-content-between align-items-center"
                        key={k}
                      >
                        <p>{item}</p>
                        <p>
                          <input
                            type="checkbox"
                            className="chkbox-square"
                            name="attendanceType"
                            value={item}
                            checked={filter.attendanceType.includes(item)}
                            onChange={onCheckChange}
                          />
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Starting Intake year</p>
                  <Form>
                    <Form.Select
                      name="intakeYear"
                      onChange={onSelectChange}
                      value={filter.intakeYear}
                    >
                      <option value="">All</option>
                      {data.IntakeYear.map((item, k) => (
                        <option value={item} key={k}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </Form>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Starting Intake Month</p>
                  <Form>
                    <Select
                      menuPortalTarget={document.getElementById("filter-col")}
                      menuPosition={"fixed"}
                      defaultValue={[]}
                      value={[]}
                      isMulti
                      options={data.IntakeMonth.filter(
                        (i) => !filter.intakeMonth.includes(i.value)
                      )}
                      placeholder=""
                      styles={customStyles}
                      className="basic-multi-select"
                      classNamePrefix="Loading..."
                      isClearable={true}
                      loading={loading.IntakeMonth}
                      onChange={(data) => onIntakeMonthSelectChange(data)}
                    />
                    {selected.intakeMonth.length > 0 && (
                      <ul className="selected-items">
                        {selected.intakeMonth.map((i, k) => (
                          <li>
                            <span className="filter-selected-items">
                              {i.label}{" "}
                            </span>
                            <button
                              onClick={() => onIntakeMonthSelectRemove(k)}
                            >
                              x
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Form>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Subjects</p>

                  <Select
                    menuPortalTarget={document.getElementById("filter-col")}
                    menuPosition={"fixed"}
                    defaultValue={[]}
                    value={[]}
                    isMulti
                    options={data.subject.filter(
                      (i) => !filter.subject.includes(i.value)
                    )}
                    placeholder=""
                    styles={customStyles}
                    className="basic-multi-select"
                    classNamePrefix="Loading..."
                    isClearable={true}
                    loading={loading.subject}
                    onChange={(data) => onSubjectSelectChange(data)}
                  />
                  {selected.subject.length > 0 && (
                    <ul className="selected-items">
                      {selected.subject.map((i, k) => (
                        <li>
                          <span className="filter-selected-items">
                            {i.label}{" "}
                          </span>
                          <button onClick={() => onSubjectSelectRemove(k)}>
                            x
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">English Exam Type</p>
                  <Form>
                    <Form.Select
                      name="examType"
                      onChange={onSelectChange}
                      value={filter.examType}
                    >
                      <option value="">All</option>
                      {data.examType.map((item, k) => (
                        <option value={item} key={k}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </Form>
                </div>
                {filter.examType !== "" && (
                  <Fragment>
                    <div className="row filter-card-wrap">
                      <div className="col-12 rwsl-wrap">
                        <div>
                          <p className="filter-cards-title">R</p>
                          <input
                            type="text"
                            className="form-control form-control1 rwsl-input"
                            name="reading"
                            value={filter.reading}
                            onChange={onInputExamChange}
                          />
                        </div>
                        <div>
                          <p className="filter-cards-title">W </p>
                          <input
                            className="form-control form-control1 rwsl-input"
                            type="text"
                            name="writing"
                            value={filter.writing}
                            onChange={onInputExamChange}
                          />
                        </div>
                        <div>
                          <p className="filter-cards-title">S </p>
                          <input
                            type="text"
                            className="form-control form-control1 rwsl-input"
                            name="speaking"
                            value={filter.speaking}
                            onChange={onInputExamChange}
                          />
                        </div>
                        <div>
                          <p className="filter-cards-title">L </p>
                          <input
                            type="text"
                            className="form-control form-control1 rwsl-input"
                            name="listening"
                            value={filter.listening}
                            onChange={onInputExamChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row filter-card-wrap">
                      <div className="col-12">
                        <p className="filter-cards-title">Reading</p>
                        <input
                          type="text"
                          className="form-control form-control1"
                          name="reading"
                          value={filter.reading}
                          onChange={onInputExamChange}
                        />
                      </div>

                      <div className="col-6">
                        <p className="filter-cards-title">Writing </p>
                        <input
                          className="form-control form-control1"
                          type="text"
                          name="writing"
                          value={filter.writing}
                          onChange={onInputExamChange}
                        />
                      </div>
                    </div>

                    <div className="row filter-card-wrap">
                      <div className="col-6">
                        <p className="filter-cards-title">Speaking </p>
                        <input
                          type="text"
                          className="form-control form-control1"
                          name="speaking"
                          value={filter.speaking}
                          onChange={onInputExamChange}
                        />
                      </div>
                      <div className="col-6">
                        <p className="filter-cards-title">Listening </p>
                        <input
                          type="text"
                          className="form-control form-control1"
                          name="listening"
                          value={filter.listening}
                          onChange={onInputExamChange}
                        />
                      </div>
                    </div> */}
                  </Fragment>
                )}

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Education Country</p>
                  <div>
                    <Form>
                      <Form.Select
                        name="educationCountry"
                        onChange={onSelectChange}
                        value={filter.educationCountry}
                      >
                        <option value="">All</option>
                        {data.educationCountry.map((item, k) => (
                          <option value={item.value} key={k}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form>
                  </div>
                </div>
                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Education Level</p>
                  <div>
                    <Form>
                      <Form.Select
                        name="educationLevel"
                        onChange={onSelectChange}
                        value={filter.educationLevel}
                      >
                        <option value="">All</option>
                        {data.educationLevel.map((item, k) => (
                          <option value={item.value} key={k}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form>
                  </div>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Grading Scheme</p>
                  <div>
                    <Form>
                      <Form.Select
                        name="gradeType"
                        onChange={onSelectChange}
                        value={filter.gradeType}
                      >
                        <option value="">All</option>
                        {data.gradeScheme.map((item, k) => (
                          <option value={item.value} key={k}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form>
                  </div>
                </div>

                {filter?.gradeType && Boolean(data.gradeScheme.length) && (
                  <>
                    {common.ArrayJsonFind(
                      "_id",
                      filter?.gradeType,
                      gradeScheme,
                      true
                    ).type === "letter" ? (
                      <div className="filter-card-wrap">
                        <p className="filter-cards-title">Grading Average</p>
                        <div>
                          <Form>
                            <Form.Select
                              name="gradeScore"
                              onChange={onSelectChange}
                              value={filter.gradeScore}
                            >
                              <option value="">All</option>
                              {common
                                .ArrayJsonFind(
                                  "_id",
                                  filter?.gradeType,
                                  gradeScheme,
                                  true
                                )
                                ?.letter_grades?.map((item, k) => (
                                  <option value={item.score} key={k}>
                                    {item.name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form>
                        </div>
                      </div>
                    ) : (
                      <div className="filter-card-wrap">
                        <p className="filter-cards-title">Grading Score</p>
                        <input
                          type="number"
                          className="form-control form-control1"
                          name="gradeScore"
                          value={filter.gradeScore}
                          onChange={(e) => {
                            if (
                              Number(e.target.value) <=
                              Number(e.target.maxLength)
                            ) {
                              onInputChange(e);
                            }
                          }}
                          maxlength={
                            common.ArrayJsonFind(
                              "_id",
                              filter?.gradeType,
                              gradeScheme,
                              true
                            )?.maxValue
                          }
                          placeholder=""
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Nationality</p>
                  <div>
                    {/* <Select
                      defaultValue={[]}
                      value={selected.nationality}
                      isMulti
                      options={data.nationality}
                      className="basic-multi-select"
                      classNamePrefix="Loading..."
                      isClearable={true}
                      loading={loading.nationality}
                      onChange={(data) =>
                        onReactSelectChange("nationality", data)
                      }
                    /> */}
                    <Select
                      defaultValue={[]}
                      value={[]}
                      isMulti
                      options={data.nationality.filter(
                        (i) => !filter.nationality.includes(i.value)
                      )}
                      placeholder=""
                      styles={customStyles}
                      className="basic-multi-select"
                      classNamePrefix="Loading..."
                      isClearable={true}
                      loading={loading.nationality}
                      onChange={(data) => onNationalitySelectChange(data)}
                    />
                    {selected.nationality.length > 0 && (
                      <ul className="selected-items">
                        {selected.nationality.map((i, k) => (
                          <li>
                            <span className="filter-selected-items">
                              {i.label}{" "}
                            </span>
                            <button
                              onClick={() => onNationalitySelectRemove(k)}
                            >
                              x
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="filter-card-wrap">
                  <p className="filter-cards-title">Valid Visa Permit</p>
                  <div>
                    <Form>
                      <Form.Select
                        name="visaPermit"
                        onChange={onSelectChange}
                        value={filter.visaPermit}
                      >
                        <option value="">All</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={9} ref={couseRef}>
              <div className="course-filter-right-side-wrap">
                <div className="d-flex align-items-center justify-content-between course-filter-right-header mb-4">
                  <div className="d-flex align-items-center course-count-wrap">
                    {/* <p className="inner-page-header-title me-3 mb-0">All Course</p>
              <p className="course-count mb-0">
                {result.pageInfo ? result.pageInfo.totalCount : 0}
              </p> */}
                    <p className="course-count-title me-3 mb-0">
                      Showing 1 – {result?.data?.length} of{" "}
                      {result.pageInfo ? result.pageInfo.totalCount : 0} results
                    </p>
                  </div>

                  <div className="d-flex justify-content-end align-items-center course-filter-wrap">
                    <label className="course-count-title me-3">Sort by</label>
                    <Form className="course-right-dropdown">
                      {
                        <Form.Select
                          className="select-sort"
                          name="sorting"
                          onChange={handleOnFilter}
                        >
                          <option value="tuitionFee:asc">
                            Fees (Low to High)
                          </option>
                          <option value="tuitionFee:desc">
                            Fees (High to Low)
                          </option>
                        </Form.Select>
                      }
                    </Form>
                  </div>
                </div>
                <ul className="course-list row">
                  {status !== "success" ? (
                    <Col md={12}>
                      <StatusBar status={status} />
                    </Col>
                  ) : (
                    result?.data.map((item, k) => (
                      <li className="col-md-4" key={k}>
                        <div
                          className={
                            isApplied(item._id)
                              ? "course-card applied-course-card"
                              : "course-card"
                          }
                        >
                          {filter?.promotionCode && (
                            <p className="promotion-applied">
                              Promotion Applied
                            </p>
                          )}
                          {item.courseTag && (
                            <p className="offer-tag">{item.courseTag}</p>
                          )}
                          <Link
                            to={`/course/info/${item._id}/${tId}/${tKey}`}
                            className="cc-header-link"
                          >
                            <div className="cc-header">
                              <h3 title={item.name} className="cc-header-title">
                                {item.name}
                              </h3>
                              <ul className="course-info-list d-flex justify-content-start align-items-center flex-wrap">
                                <li className="me-2 mb-2">
                                  <img
                                    src={common.loadImg("level-icn.svg")}
                                    alt="level-icon"
                                    className="cc-header-icn"
                                  />
                                  {item.courseLevel}
                                </li>
                                <li className="me-2 mb-2">
                                  <img
                                    src={common.loadImg("duration-icn.svg")}
                                    alt="duration-icon"
                                    className="cc-header-icn"
                                  />
                                  {item.courseDurationType} Year
                                </li>
                                {offerUI(item)}
                              </ul>
                            </div>
                          </Link>
                          <div className="cc-body">
                            <Link
                              to={`/university/info/${item.university._id}/${tId}/${tKey}`}
                              className="card-course-title-link"
                            >
                              <div className="university-info-wrap d-flex justify-content-between align-items-center">
                                <div className="university-logo-wrap">
                                  <img
                                    src={common.cloudImg(item.university?.logo)}
                                    alt={item.university?.name}
                                  />
                                </div>
                                <div className="university-info">
                                  <h4>{item.university?.name}</h4>
                                  <p>{item?.city?.name}</p>
                                </div>
                              </div>
                            </Link>
                            <div className="course-desc">
                              <Link
                                className="course-desc-link"
                                to={`/course/info/${item._id}/${tId}/${tKey}`}
                              >
                                {/* {common.trimString(item.shortDesc, 60)} */}
                                {item.shortDesc}
                              </Link>
                            </div>
                          </div>
                          <div className="cc-footer">
                            <div className="d-flex justify-content-between align-items-center course-card-footer">
                              <div>
                                <p className="amount">
                                  {common.currencyToSymbol(item.currency)}{" "}
                                  {common.numberWithCommas(item.tuitionFee)}
                                </p>
                                <p className="label label-sm">Per Annum</p>
                              </div>
                              <div>
                                {isApplied(item._id) ? (
                                  <p className="apply-success">Applied</p>
                                ) : (
                                  <p
                                    className="apply-now"
                                    onClick={() => applyCourse(item._id)}
                                  >
                                    {getApplyText()}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
                {status === "success" &&
                  result?.pageInfo?.totalCount > result?.data?.length && (
                    <Row>
                      <Col
                        className="course-finder-pagination d-flex justify-content-end mt-4"
                        lg={12}
                      >
                        <button
                          className="btn btn-load-more"
                          onClick={handlePageChange}
                          disabled={loading.loadMore}
                        >
                          {loading.loadMore
                            ? "Loading data..."
                            : "Load more data"}
                        </button>
                      </Col>
                    </Row>
                  )}
              </div>
            </Col>
          </Row>
        </Container>
        <CourseSelected
          tKey={tKey}
          status={studentInfoStatus}
          result={studentInfo}
          studentName={studentInfo?.profile?.name}
          onReload={() => {
            studentProgress("load");
          }}
        />
        {/* modal */}
        {courseId !== null && (
          <CoursePickup
            tId={tId}
            tKey={tKey}
            courseId={courseId}
            onClose={() => {
              setCourseId(null);
            }}
            onSuccess={() => {
              setCourseId(null);
              studentProgress("load");
            }}
          />
        )}
      </div>
      {infoModal !== null && (
        <ModalInfo data={infoModal} onYes={() => setInfoModal(null)} />
      )}
    </Fragment>
  );
}

export default Finder;
