import React, { useEffect, useState, Fragment, useRef } from "react";
import Select from "react-select";
import moment from "moment";
// mui
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
// bootstrap
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import DateRangePicker from "react-bootstrap-daterangepicker";
// includes
import FieldSelect from "elements/fieldSelect";
import { api, common, config, types } from "helpers";

function AgFilter(props) {
  // props
  const { filterData } = props;
  // state
  const [show, setShow] = useState(false);
  const [finder, setFinder] = useState({
    agentSingle: true,
    agentMulti: true,
    studentSingle: true,
    studentMulti: true,
    universitySingle: true,
    universityMulti: true,
    courseSingle: true,
    courseMulti: true,
    planMulti: true,
  });
  const [fields, setFields] = useState(common.filterParse(filterData, true));
  const [FieldLog, setFieldLog] = useState(
    common.filterParse(filterData, true)
  );
  const [selectRender, setSelectRender] = useState({
    country: "yes",
    province: "no",
    city: "no",
  });
  // effect
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    if (props.filterReset) {
      setFields(common.filterReset(filterData, true));
    }
  }, [props.filterReset]);

  // render
  return (
    show && (
      <Offcanvas show={show} responsive="lg" placement="end">
        <Offcanvas.Header
          closeButton
          onClick={() => {
            setFields(FieldLog);
            props.onClose();
          }}
        >
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="filter-col-wrap">
            {/* <h3 className="filter-heading">Course Finder</h3> */}
            <div className="d-flex justify-content-between align-items-center px-2 filter-clear-wrap">
              <p>
                <img src={common.loadImg("filter-icn.svg")} alt="" />{" "}
                <span className="ps-2">Filter</span>
              </p>
              <p
                className="clear"
                onClick={() => {
                  setFields(common.filterReset(filterData, true));
                }}
              >
                Clear
              </p>
            </div>
            {/* Single & Multi Select */}
            {[
              {
                key: "agentSingle",
                title: "Select Agent",
                multi: false,
                api: "common_data",
                query: "/agent?offset=1&limit=1000",
                placeholder: "Please select the agent",
                column: ["_id", "name"],
              },
              {
                key: "agentMulti",
                title: "Select Agent",
                multi: true,
                api: "common_data",
                query: "/agent?offset=1&limit=1000",
                placeholder: "Please select the agent",
                column: ["_id", "name"],
              },
              {
                key: "studentSingle",
                title: "Select Student",
                multi: false,
                api: "common_data",
                query: "/student?offset=1&limit=1000",
                placeholder: "Please select the student",
                column: ["_id", "email"],
              },
              {
                key: "studentMulti",
                title: "Select Student",
                multi: true,
                api: "common_data",
                query: "/student?offset=1&limit=1000",
                placeholder: "Please select the student",
                column: ["_id", "email"],
              },
              {
                key: "universitySingle",
                title: "Select University",
                multi: false,
                api: "common_data",
                query: "/university?offset=1&limit=1000",
                placeholder: "Please select the university",
                column: ["_id", "name"],
              },
              {
                key: "universityMulti",
                title: "Select University",
                multi: true,
                api: "common_data",
                query: "/university?offset=1&limit=1000",
                placeholder: "Please select the university",
                column: ["_id", "name"],
              },
              {
                key: "courseSingle",
                title: "Select Course",
                multi: false,
                api: "common_data",
                query: `/course?query=${JSON.stringify({
                  isActive: true,
                })}&offset=1&limit=1000`,
                placeholder: "Please select the course",
                column: ["_id", "name"],
              },
              {
                key: "courseMulti",
                title: "Select Course",
                multi: true,
                api: "common_data",
                query: `/course?query=${JSON.stringify({
                  isActive: true,
                })}&offset=1&limit=1000`,
                placeholder: "Please select the course",
                column: ["_id", "name"],
              },
              {
                key: "planMulti",
                title: "Select Plan",
                multi: false,
                api: "common_data",
                query: "/plan?offset=1&limit=1000",
                placeholder: "Please select the plan",
                column: ["_id", "title"],
              },
            ].map(
              (i, k) =>
                filterData.hasOwnProperty(i.key) && (
                  <div className="filter-card-wrap" key={k}>
                    <p className="filter-cards-title">{i.title}</p>
                    <FieldSelect
                      api={i.api}
                      query={i.query}
                      get={i.column[1]}
                      set={i.column[0]}
                      value={fields[i.key]}
                      placeholder={i.placeholder}
                      render={"yes"}
                      clearable={true}
                      finder={finder[i.key]}
                      multi={i.multi}
                      onSelect={(data) => {
                        setFields((prev) => ({
                          ...prev,
                          [i.key]: data,
                        }));
                        if (finder[i.key]) {
                          setFinder((prev) => ({
                            ...prev,
                            [i.key]: false,
                          }));
                          setFieldLog((prev) => ({
                            ...prev,
                            [i.key]: data,
                          }));
                        }
                      }}
                    />
                  </div>
                )
            )}

            {/* Boolean */}
            {[
              { key: "published", title: "Is Published" },
              { key: "status", title: "Status" },
              { key: "verified", title: "Is Verfied" },
              { key: "applicationSelect", title: "Application Status" },
            ].map(
              (i, k) =>
                filterData.hasOwnProperty(i.key) && (
                  <div className="filter-card-wrap" key={k}>
                    <p className="filter-cards-title">{i.title}</p>
                    <Select
                      className="custom-select-box"
                      value={fields[i.key]}
                      options={types[i.key]}
                      isClearable={true}
                      onChange={(data) => {
                        setFields((prev) => ({ ...prev, [i.key]: data }));
                      }}
                    />
                  </div>
                )
            )}

            {/* Price Slider */}
            {filterData.hasOwnProperty("feesSlider") && (
              <div className="filter-card-wrap">
                <p className="filter-cards-title">Course Fee</p>​
                <Box className="score-slider">
                  <Slider
                    getAriaLabel={() => "Course Fee"}
                    valueLabelDisplay="auto"
                    value={fields.feesSlider}
                    min={1}
                    max={10000000}
                    onChange={(event, value) => {
                      setFields((prev) => ({ ...prev, feesSlider: value }));
                    }}
                  />
                </Box>
              </div>
            )}

            {/* Datepicker */}
            {[
              { key: "dateA" },
              { key: "dateB" },
              { key: "dateC" },
              { key: "dateD" },
            ].map(
              (i, k) =>
                filterData.hasOwnProperty(i.key) && (
                  <div className="filter-card-wrap" key={k}>
                    <p className="filter-cards-title">
                      {filterData[i.key].title}
                    </p>
                    <div className="date-picker">
                      <DateRangePicker
                        initialSettings={{
                          ...config.elements.singleDp,
                        }}
                        onCallback={(start) => {
                          setFields((prev) => ({
                            ...prev,
                            [i.key]: moment(start).format(config.elements.dp.A),
                          }));
                        }}
                      >
                        <input
                          type="text"
                          className="form-control date-picker"
                          value={fields[i.key]}
                          placeholder="Select the date"
                          readOnly={true}
                        />
                      </DateRangePicker>
                      <button
                        className="clear-icn"
                        onClick={() => {
                          setFields((prev) => ({
                            ...prev,
                            [i.key]: "",
                          }));
                        }}
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          className="css-tj5bde-Svg"
                        >
                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                )
            )}

            {/* Country, privince & city */}
            {filterData.hasOwnProperty("country") && (
              <div className="filter-card-wrap">
                <p className="filter-cards-title">Select Country</p>
                <FieldSelect
                  api="common_country"
                  query="?all=show"
                  get="name"
                  set="code"
                  value={fields.country}
                  placeholder="Please select the country"
                  render={selectRender.country}
                  clearable={true}
                  finder={false}
                  multi={false}
                  onSelect={(data) => {
                    setFields((prev) => ({
                      ...prev,
                      country: data,
                      province: null,
                      city: null,
                    }));
                    setSelectRender((prev) => ({
                      ...prev,
                      province: data === null ? "empty" : new Date(),
                      city: "empty",
                    }));
                  }}
                />
              </div>
            )}
            {filterData.province && (
              <div className="filter-card-wrap">
                <p className="filter-cards-title">Select Province</p>
                <FieldSelect
                  api="common_state"
                  query={`/${fields?.country?.value}?&offset=1&limit=1000`}
                  get="name"
                  set="_id"
                  value={fields.province}
                  placeholder="Please select the province"
                  render={selectRender.province}
                  clearable={true}
                  finder={false}
                  multi={false}
                  onSelect={(data) => {
                    setFields((prev) => ({
                      ...prev,
                      province: data,
                      city: null,
                    }));
                    setSelectRender((prev) => ({
                      ...prev,
                      city: data === null ? "empty" : new Date(),
                    }));
                  }}
                />
              </div>
            )}
            {filterData.city && (
              <div className="filter-card-wrap">
                <p className="filter-cards-title">Select City</p>
                <FieldSelect
                  api="common_city"
                  query={`/${fields?.country?.value}/${fields?.province?.value}?&offset=1&limit=1000`}
                  get="name"
                  set="_id"
                  value={fields.city}
                  placeholder="Please select the city"
                  render={selectRender.city}
                  clearable={true}
                  finder={false}
                  multi={false}
                  onSelect={(data) => {
                    setFields((prev) => ({
                      ...prev,
                      city: data,
                    }));
                  }}
                />
              </div>
            )}
            <div className="lfilter-footer d-flex justify-content-between align-items-center mt-4">
              <button
                className="btn btn-submit"
                onClick={() => {
                  setFieldLog(fields);
                  props.onSuccess(fields);
                }}
              >
                Submit
              </button>
              <button
                className="btn btn-cancel"
                onClick={() => {
                  setFields(FieldLog);
                  props.onClose();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    )
  );
}

export default AgFilter;
