import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import AmlHashForm from "aml-hash-form";
// includes
import { api, common } from "helpers";

function Four(props) {
  // state
  const [loading, setLoading] = useState(false);
  const [fileA, setFileA] = useState([]);
  const [fileB, setFileB] = useState([]);
  const [tempData] = useState(common.tempDataGet());
  // form
  const formFields = {
    fullName1: {
      validate: [],
    },
    designation1: {
      validate: [],
    },
    emailAddress1: {
      validate: ["email"],
    },
    fullName2: {
      validate: [],
    },
    designation2: {
      validate: [],
    },
    emailAddress2: {
      validate: ["email"],
    },
    businessRegDocs: {
      validate: [],
      // validate: [
      //   "req#Please upload the business document",
      //   "gt:0#Please upload the business document",
      // ],
    },
    proofIdentityDocs: {
      validate: [],
      // validate: [
      //   "req#Please upload your proof",
      //   "gt:0#Please upload your proof",
      // ],
    },
  };

  const { fields, errors, setValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // api
  const register = async () => {
    setLoading(true);
    let aResult = {};
    let bResult = {};
    let error = "";
    // upload 1
    if (fileA.length > 0) {
      aResult = await uploadFile("fileA");
      if (aResult.status !== 200) {
        error = aResult.data.error;
      }
    }
    // upload 2
    if (!error && fileB.length > 0) {
      bResult = await uploadFile("fileB");
      if (bResult.status !== 200) {
        error = bResult.data.error;
      }
    }
    // call
    if (error) {
      common.notify("E", error);
      setLoading(false);
    } else {
      let params = {
        businessRegDocs: aResult?.data?.link || null,
        proofIdentityDocs: bResult?.data?.link || null,
        reference: [
          {
            fullName: fields.fullName1,
            designation: fields.designation1,
            email: fields.emailAddress1,
          },
          {
            fullName: fields.fullName2,
            designation: fields.designation2,
            email: fields.emailAddress2,
          },
        ],
      };
      let data = {
        url: "agent_profile",
        method: "PUT",
        auth: "temp",
        body: params,
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", "Reference & Document details has been updated");
          common.tempDataSet({ ...tempData, page: 5 });
          props?.onSuccess();
        } else if (res.status === 500) {
          common.notify("E", res.data.error);
        }
        setLoading(false);
      });
    }
  };

  // file handler
  const onFileChangeA = (e) => {
    const files = e.target.files;
    setFileA((prev) => {
      let nData = [...prev, ...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }
      setValue("businessRegDocs", result.length);
      return result;
    });
  };

  const onFileChangeB = (e) => {
    const files = e.target.files;
    setFileB((prev) => {
      let nData = [...prev, ...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }
      setValue("proofIdentityDocs", result.length);
      return result;
    });
  };

  const deleteFileA = (key) => {
    setFileA((prev) => {
      let result = [];
      prev.map((i, k) => {
        if (k !== key) {
          result.push(i);
        }
      });
      setValue("businessRegDocs", result.length);
      return result;
    });
  };

  const deleteFileB = (key) => {
    setFileB((prev) => {
      let result = [];
      prev.map((i, k) => {
        if (k !== key) {
          result.push(i);
        }
      });
      setValue("proofIdentityDocs", result.length);
      return result;
    });
  };

  const uploadFile = async (idx) => {
    var formdata = new FormData();
    if (idx == "fileA") {
      fileA.map((i, k) => {
        formdata.append("file", i);
      });
    } else {
      fileB.map((i, k) => {
        formdata.append("file", i);
      });
    }
    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      auth: "temp",
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  // submit handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      register();
    }
  };

  // render
  return (
    <div className="row inner-page-bg">
      <Container className="register-step-container agent-signup-wrap">
        <div className="col-12 py-5">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="row">
                <Col lg={12}>
                  <Row className="right-content-width remove-border">
                    <Col lg={12} className="p-0">
                      <p className="ref-heading1">
                        Reference check from the same industry
                      </p>
                    </Col>
                  </Row>
                  {Array(2)
                    .fill()
                    .map((i, k) => {
                      ++k;
                      return (
                        <div key={k}>
                          <Row className="right-content-width remove-border">
                            <Col lg={12} className="register-step-header">
                              <p className="register-step-title mb-0">
                                {k}. Reference
                              </p>
                            </Col>
                          </Row>

                          <Row className="agent-box-body">
                            <Col lg={6}>
                              <label className="label-padding">Full Name</label>
                              <div className="signin-form-row">
                                <input
                                  type="text"
                                  name={"fullName" + k}
                                  className="form-control"
                                  value={fields["fullName" + k]}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <label className="label-padding">
                                Designation
                              </label>
                              <div className="signin-form-row">
                                <input
                                  type="text"
                                  name={"designation" + k}
                                  className="form-control"
                                  value={fields["designation" + k]}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <label className="label-padding">
                                Email Address
                              </label>
                              <div className="signin-form-row">
                                <input
                                  type="text"
                                  name={"emailAddress" + k}
                                  className="form-control"
                                  value={fields["emailAddress" + k]}
                                  onChange={handleChange}
                                />
                              </div>
                              <p className="error-txt">
                                {errors["emailAddress" + k]}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}

                  <Row className="right-content-width remove-border">
                    <Col lg={12} className="register-step-header">
                      <p className="register-step-title mb-0">Documents</p>
                    </Col>
                  </Row>
                  <Row className="agent-box-body">
                    <div className="col-md-6">
                      <div className="agt-proof-upload d-flex justify-content-between align-items-center">
                        <div>
                          <p className="agt-proof-title mb-0">
                            Business Registration
                          </p>
                          <p className="mb-0 error-txt">
                            {errors.businessRegDocs}
                          </p>
                        </div>
                        <div>
                          <input
                            type="file"
                            id="file-1"
                            className="et-hide"
                            name="businessRegDocs"
                            value=""
                            multiple={true}
                            onChange={(e) => onFileChangeA(e)}
                          />
                          <label
                            className="btn btn-outline-secondary delete-btn-padding"
                            type="button"
                            htmlFor="file-1"
                            id="button-addon2"
                          >
                            <img
                              src={common.loadImg("paper-dark-upload.svg")}
                            />
                          </label>
                        </div>
                      </div>
                      <p className="mb-0">
                        (Maximum 3 files and per file size is 10 MB)
                      </p>
                      {fileA.map((i, k) => (
                        <div
                          className="d-flex align-items-center justify-content-between pt-3"
                          key={k}
                        >
                          <p className="agt-proof-file mb-0">{i.name}</p>
                          <img
                            src={common.loadImg("iconly-dark-delete.svg")}
                            className="et-cp"
                            onClick={() => deleteFileA(k)}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="col-md-6">
                      <div className="agt-proof-upload d-flex justify-content-between align-items-center">
                        <div>
                          <p className="agt-proof-title mb-0">
                            Proof of Identity{" "}
                            <span className="applicable-text">
                              (If applicable)
                            </span>
                          </p>
                          <p className="mb-0 error-txt">
                            {errors.proofIdentityDocs}
                          </p>
                        </div>
                        <div>
                          <input
                            type="file"
                            id="file-2"
                            className="et-hide"
                            name="proofIdentityDocs"
                            multiple={true}
                            value=""
                            onChange={(e) => onFileChangeB(e)}
                          />
                          <label
                            className="btn btn-outline-secondary delete-btn-padding"
                            type="button"
                            htmlFor="file-2"
                            id="button-addon2"
                          >
                            <img
                              src={common.loadImg("paper-dark-upload.svg")}
                            />
                          </label>
                        </div>
                      </div>
                      <p className="mb-0">
                        (Maximum 3 files and per file size is 10 MB)
                      </p>
                      {fileB.map((i, k) => (
                        <div
                          className="d-flex align-items-center justify-content-between pt-3"
                          key={k}
                        >
                          <p className="agt-proof-file mb-0">{i.name}</p>
                          <img
                            src={common.loadImg("iconly-dark-delete.svg")}
                            className="et-cp"
                            onClick={() => deleteFileB(k)}
                          />
                        </div>
                      ))}
                    </div>
                  </Row>

                  <Row className="regfooter">
                    {/* <Col lg={12}>
                      <p>
                        Quii ipsorum lingua Celtae, nostra Galli appellantyr.
                        Quisque ut dolor gravida, placerat libero ve, euismod.
                        Hi omnes lingua, institutis, legibus inter se differunt.
                        Quii ipsorum lingua Celtae, nostra Galli appellantur.
                        Quisque ut dolor gravida, placerat libero vel
                      </p>
                    </Col> */}
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      className="d-flex align-items-center"
                    >
                      <p>
                        I agree to the &nbsp;
                        <Link to="" className="text-decoration-underline">
                          Terms and Conditions
                        </Link>
                        &nbsp;by clicking Submit
                      </p>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      className="text-end px-5 pb-5 pt-5"
                    >
                      <button
                        className="btn-default" // register-step-btn
                        onClick={onSubmit}
                        disabled={loading}
                      >
                        {!loading ? "Signup" : "Processing"}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Four;
