import moment from "moment-timezone";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
// includes
import { api, common } from "helpers";

export default function Otp() {
  // init
  const navigate = useNavigate();

  // state
  const [init, setInit] = useState(false);
  const [localData] = useState(common.localDataGet());
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [timeOut, setTimeOut] = useState(59);
  const [loading, setLoading] = useState(false);

  // effect
  useEffect(() => {
    if (!localData) {
      navigate("/login");
    } else {
      setInit(true);
    }
  }, []);

  useEffect(() => {
    const timer =
      timeOut > 0 && setInterval(() => setTimeOut(timeOut - 1), 1000);
    return () => clearInterval(timer);
  }, [timeOut]);

  // api
  const onSubmit = () => {
    if (otp.length < 6) {
      setError("Enter the otp");
    } else {
      setLoading(true);
      // call
      let data =
        localData.type === "passwordForgot"
          ? {
              url: "ids_otp_reset",
              method: "POST",
              auth: "none",
              body: {
                email: localData.email,
                otp: otp,
                timeZone: moment.tz.guess(),
              },
            }
          : {
              url: "ids_otp_verify",
              method: "POST",
              auth: "none",
              body: {
                otp,
              },
            };

      api.call(data, (res) => {
        if (res.status === 200) {
          let data = res.data;
          if (localData.type === "passwordForgot") {
            common.localDataSet({
              email: localData.email,
              // resetToken: data.resetToken,
              otp,
            });
            navigate("/password/reset");
          } else if (localData.type === "studentRegister") {
            common.notify("S", "Registration has been successfull");
            common.localSet("authData", common.crypt(data, true));
            navigate("/student/home");
          }
        } else if (res.status === 500) {
          setError(res.data.error);
        }
        setLoading(false);
      });
    }
  };

  const otpGenerator = () => {
    let data =
      localData.type === "passwordForgot"
        ? {
            url: "auth_otp_verify",
            method: "POST",
            auth: "none",
            body: {
              email: localData.email,
            },
          }
        : {
            url: "ids_sign_up",
            method: "POST",
            auth: "none",
            body: {
              ...localData,
            },
          };
    api.call(data, (res) => {
      if (res.status === 200) {
        setTimeOut(59);
        common.notify("S", "Otp has been sent to " + localData.email);
      } else if (res.status === 500) {
        setError(res.data.error);
      }
    });
  };

  // support
  const goBack = (e) => {
    e.preventDefault();
    if (localData.type === "passwordForgot") {
      common.localDataSet({
        email: localData.email,
      });
    } else if (localData.type === "studentRegister") {
      common.localDataSet(localData);
    }
    navigate(-1);
  };

  // render
  return (
    init && (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 et-page-wrap otp-page-wrap">
            <div className="row">
              <div className="col-md-5 mx-auto">
                <div className="box-header">
                  <p className="title">OTP Verification</p>
                </div>
                <div className="otp-body">
                  <div>
                    <label className="form-label">
                      OTP sent to <b>{localData?.email}</b>
                    </label>
                  </div>
                  <div className="otp-section d-flex justify-content-center">
                    <OtpInput
                      value={otp}
                      onChange={(value) => {
                        setError(null);
                        setOtp(value);
                      }}
                      numInputs={6}
                      isInputNum={true}
                      className={error && "otp-error"}
                      inputStyle={{
                        width: "54px",
                        height: "54px",
                        color: "#000",
                        fontWeight: "400",
                        fontSize: "22px",
                        caretColor: "blue",
                      }}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div className="mx-5">
                    <p className="error-txt">{error}</p>
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <div className="d-flex">
                      <p className="rememberlogin-text">Didn't receive code?</p>
                    </div>
                    <div>
                      {timeOut ? (
                        <p>Resend OTP in 0:{timeOut} </p>
                      ) : (
                        <a
                          onClick={otpGenerator}
                          className="rememberlogin-text"
                        >
                          Resend code
                        </a>
                      )}
                    </div>
                  </div>

                  <div className=" justify-content-between mt-3">
                    <div>
                      <a
                        href="#"
                        className="rememberlogin-text"
                        onClick={goBack}
                      >
                        Back
                      </a>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className={loading ? "btn-signin-disable" : "btn-signin"}
                      disabled={loading ? loading : false}
                      onClick={onSubmit}
                    >
                      {loading ? "Processing" : "Submit"}
                    </button>
                  </div>
                </div>

                <div className="shadow-div"></div>
                <div className="greenshow-div"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
