import React from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { Fragment } from "react";

export default function List() {
  // init
  const navigate = useNavigate();

  // functions
  const callAction = (data) => {
    if (data.type == "Add" || data.type == "add") {
      navigate("add");
    } else if (data.type == "View") {
      navigate("edit/" + data.item._id);
    }
  };
  return (
    <Fragment>
      {true && (
        <AgTable
          module="promoCode"
          filterData={{
            status: { key: "isActive", value: "" },
            dateA: { key: "startDate", value: "", title: "Promo Start Date" },
            dateB: { key: "endDate", value: "", title: "Promo End Date" },
          }}
          onAction={(data) => callAction(data)}
        />
      )}
    </Fragment>
  );
}
