import AmlHashForm from "aml-hash-form";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
// includes
import { api, common } from "helpers";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ModalAgreement from "elements/modalAgreement";

export default function Agreement(props) {
  const [agreement, setAgreement] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFileProcessing, setIsFileProcessing] = useState(false);
  const sigRef = useRef();
  const [signature, setSignature] = useState(null);

  // form
  const formFields = {
    accHolder: {
      validate: ["req#Account Holder Name is required"],
    },
    accountNumber: {
      validate: ["req#Account Number is required"],
    },
    sortCode: {
      validate: ["req#Sort Code is required!"],
    },
    bankName: {
      validate: ["req#Bank Name is required"],
    },
    bankIban: {
      // validate: ["req#IBAN is required"],
      validate: [],
    },
    bankCountry: {
      // validate: ["req#Bank Country is required"],
      validate: [],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  useEffect(() => {
    getAllInfo();
  }, []);

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  const getAllInfo = () => {
    let data = {
      url: "agent_profile",
      method: "GET",
      auth: "temp",
    };
    api.call(data, (res) => {
      let result = res.data;

      setMultiValue({
        accHolder: result?.accHolder,
        accountNumber: result?.accountNumber,
        sortCode: result?.sortCode,
        bankName: result?.bankName,
        bankIban: result?.bankIban,
        bankCountry: result?.bankCountry,
      });
      if (
        result?.accHolder &&
        result?.accountNumber &&
        result?.sortCode &&
        result?.bankName &&
        result?.bankIban &&
        result?.bankCountry &&
        result?.signature
      ) {
        getProcessStatus();
      }
    });
  };

  const onSubmit = (e) => {
    let isValid = handleSubmit();
    if (isValid) {
      if (signature) {
        // save();
        confirmSubmit();
      } else {
        common.notify(
          "E",
          "Please add your signature to complete registeration"
        );
      }
    }
  };

  const confirmSubmit = () => {
    // confirmAlert({
    //   title: "Accuracy of Information for Final Agreement",
    //   message: "Please ensure the accuracy of the information provided for the final agreement. It is crucial that all details are correct and up to date to ensure the validity and effectiveness of the agreement.",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
    //         save();
    //       },
    //     },
    //     {
    //       label: "No",
    //     },
    //   ],
    // });
    setAgreement(true);
  };

  const getProcessStatus = () => {
    let data = {
      url: "agent_pdf_process",
      method: "GET",
      auth: "temp",
    };
    api.call(data, async (res) => {
      if (res.status === 200) {
        setIsUpdating(false);
        props.onSuccess();
        // if (res.data?.fileName) {
        // setIsFileProcessing(true);
        // downloadPdf(res.data?.fileName);
        // }
      }
    });
  };

  const downloadPdf = (fileName) => {
    let data = {
      url: "agent_pdf_download",
      method: "GET",
      auth: "temp",
      responseType: "file",
      fileName: fileName,
      extensions: "pdf",
    };
    api.call(data, async (res) => {
      if (res) {
        setIsFileProcessing(false);
        props.onSuccess();
      }
    });
  };

  const save = () => {
    setIsUpdating(true);
    fields.signature = signature;
    let data = {
      url: "agent_profile",
      method: "PUT",
      auth: "temp",
      body: fields,
    };

    api.call(data, (res) => {
      let result = res?.data;
      if (res.status === 200) {
        // if (
        //   result?.accHolder &&
        //   result?.accountNumber &&
        //   result?.sortCode &&
        //   result?.bankName &&
        //   result?.bankIban &&
        //   result?.bankCountry &&
        //   result?.signature
        // ) {
        getProcessStatus();
        // }
      }
    });
  };

  return (
    <div className="row">
      <div className="col-12 agent-signup-wrap py-5">
        <div className="row">
          <div className="col-md-10 mx-auto ">
            <div className="right-content-width remove-border">
              <p className="ref-heading1">Bank Details</p>
              <div className="agent-box-body">
                <div className="row">
                  <div className="col-md-6">
                    <label className="label-padding">
                      Bank Account Holder Name{" "}
                    </label>
                    <div className="signin-form-row">
                      <input
                        type="text"
                        name="accHolder"
                        placeholder="Enter the account holders name"
                        value={fields.accHolder}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                    <p className="error-txt">{errors.accHolder}</p>
                  </div>
                  <div className="col-md-6">
                    <label className="label-padding">
                      Bank Account Number{" "}
                    </label>
                    <div className="signin-form-row">
                      <input
                        type="text"
                        name="accountNumber"
                        placeholder="Enter the account Number"
                        value={fields.accountNumber}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                    <p className="error-txt">{errors.accountNumber}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label className="label-padding">Bank Name</label>
                    <div className="signin-form-row">
                      <input
                        type="text"
                        name="bankName"
                        placeholder="Enter the bank name"
                        value={fields.bankName}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                    <p className="error-txt">{errors.bankName}</p>
                  </div>
                  <div className="col-md-6">
                    <label className="label-padding">Sort Code </label>
                    <div className="signin-form-row">
                      <input
                        type="text"
                        name="sortCode"
                        placeholder="Enter the sort code"
                        value={fields.sortCode}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                    <p className="error-txt">{errors.sortCode}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label className="label-padding">IBAN </label>
                    <div className="signin-form-row">
                      <input
                        type="text"
                        name="bankIban"
                        placeholder="Enter IBAN"
                        value={fields.bankIban}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                    <p className="error-txt">{errors.bankIban}</p>
                  </div>
                  <div className="col-md-6">
                    <label className="label-padding">Bank Country</label>
                    <div className="signin-form-row">
                      <input
                        type="text"
                        name="bankCountry"
                        placeholder="Enter the bank country"
                        value={fields.bankCountry}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                    <p className="error-txt">{errors.bankCountry}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="right-content-width remove-border">
              <p className="ref-heading1">Signature</p>
              <div className="agent-box-body">
                <div className="row ">
                  <label className="mb-2 ml-2"> Add your signature here</label>
                  <div className="col-lg-6 d-flex align-items-center ">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ className: "signature" }}
                      ref={sigRef}
                      onEnd={handleSignatureEnd}
                    />
                    <Link
                      to=""
                      className="clear-sign ms-2"
                      onClick={clearSignature}
                    >
                      Clear
                    </Link>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end align-items-center">
                    <button
                      className="btn-default me-3"
                      onClick={onSubmit}
                      disabled={isUpdating}
                    >
                      {isUpdating ? "Processing ..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isFileProcessing && (
          <Modal show={true} size={"md"} className="modal-download-doc">
            <Modal.Body>
              <div className="download-modal text-center">
                <img
                  src={common.loadImg("edutech-loader.gif")}
                  className="loader-img"
                  alt="application-icn"
                />
                <div> Please wait... agreement document downloading...</div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {agreement && (
          <ModalAgreement
            loading={isUpdating}
            onYes={save}
            onNo={() => setAgreement(false)}
          />
        )}
      </div>
    </div>
  );
}
