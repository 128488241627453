import { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// includes
import ModalConfirm from "elements/modalConfirm";
import { api, common, message } from "helpers";

function CourseSelected(props) {
  // const
  const { tKey } = props;
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState(null);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  // effect
  useEffect(() => {
    setResult(props.result);
    setStatus(props.status);
  }, [props.result]);

  // api
  const deleteCourse = () => {
    setLoading(true);
    let data = {
      url: "course_pickup",
      method: "DELETE",
      query: `/${tKey.split(":")[0]}/${deleteData.id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", message.cb);
        props.onReload();
      } else {
        common.notify("E", message.ca);
      }
      setDeleteData(null);
      setLoading(false);
    });
  };
  return (
    status === "success" && (
      <div className="selected-courses-wrap">
        <Accordion defaultActiveKey={[]}>
          <Accordion.Item eventKey="0">
            <div className="accordiontext">
              <div>
                <Accordion.Header>
                  Selected Course{" "}
                  <span className="selected-course-count">
                    {result.course.picked.length}
                  </span>
                </Accordion.Header>
              </div>
              <div>
                <Link
                  to={
                    result.course.picked.length >= 1
                      ? `/application/preview/${tKey.split(":")[0]}`
                      : ""
                  }
                  className="text-dec-none"
                >
                  <button
                    className="btn-signin d-flex justify-content-between align-items-center"
                    disabled={result.course.picked.length === 0}
                  >
                    <span className="ms-3">Proceed Now</span>
                    <img
                      src={common.loadImg("circle-arrow.svg")}
                      className="me-3"
                      alt="promotion-icn"
                    ></img>
                  </button>
                </Link>
              </div>
            </div>
            <Accordion.Body>
              {result.course.picked.length === 0 ? (
                <p>No course selected yet</p>
              ) : (
                result.course.picked.map((i, k) => (
                  <div className="Coursecont selected-course-list ps-4" key={k}>
                    <Col lg={10}>
                      <Row>
                        <Col lg={12}>
                          <span className="accordhead course-counter">
                            Course Name
                          </span>
                          <p className="accordcont">{i.course.name}</p>
                        </Col>
                        <Col lg={6}>
                          <span className="accordhead">University</span>
                          <p className="accordcont">{i.university?.name}</p>
                        </Col>
                        <Col lg={6}>
                          <span className="accordhead">
                            Intake Year & Month
                          </span>
                          <p className="accordcont">
                            {i.year} {common.getMonthList()[i.month - 1].label}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={2}>
                      <img
                        src={common.loadImg("del-icon-blue.svg")}
                        className="et-cp"
                        onClick={() => setDeleteData({ id: i._id })}
                      />
                    </Col>
                  </div>
                ))
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {deleteData !== null && (
          <ModalConfirm
            loading={loading}
            onYes={() => deleteCourse()}
            onNo={() => setDeleteData(null)}
          />
        )}
      </div>
    )
  );
}

export default CourseSelected;
