import { Fragment, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
// includes
import StatusBar from "elements/statusBar";
import { api, common } from "helpers";
import CoursePickup from "includes/coursePickup";
import CommonStudentView from "includes/studentView";

function Preview() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // props
  let { id } = useParams();
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [status, setStatus] = useState("process");
  const [courseStatus, setCourseStatus] = useState(null);
  const [fields, setFields] = useState({});
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [courseId, setCourseId] = useState(null);
  // effect
  useEffect(() => {
    checkProfile();
  }, []);

  // api
  const checkProfile = () => {
    let data = {};
    if (authInfo.role === "student") {
      data = {
        url: "student_info",
        method: "GET",
      };
    } else {
      data = {
        url: "agent_student_info",
        query: `/${id}`,
        method: "GET",
      };
    }

    api.call(data, (res) => {
      if (res.status === 200) {
        const { progress, course } = res.data;
        // check status
        if (progress.total !== 100) {
          common.notify(
            "I",
            "Please complete the profile and proceed the application"
          );
          navigate(
            authInfo.role === "student"
              ? "/student/profile"
              : `course/finder/${id}`
          );
        } else if (course.picked.length === 0) {
          common.notify(
            "I",
            "Please select the course and proceed the application"
          );
          navigate(
            authInfo.role === "student"
              ? "/student/profile"
              : `course/finder/${id}`
          );
        } else {
          getInfo();
        }
      } else {
        if (authInfo.role === "student") {
          common.notify("W", "Something went wrong");
          navigate("/student/list");
        } else {
          common.notify("W", "Please select the valid student and add course");
          navigate("/agent/student/list");
        }
      }
    });
  };

  const getInfo = () => {
    let status = "success";
    let data = {
      url: "course_pickup",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status !== 200) {
        status = "error";
      } else {
        setResult(res.data);
      }
      setStatus(status);
    });
  };

  const onSubmit = () => {
    // validate
    setLoading(true);
    let data = {
      url: "application",
      method: "POST",
      query: `/${id}`,
      body: {},
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        const { orderId, paid } = res.data;
        if (paid) {
          common.notify("S", "Application created successfully");
          navigate(`/application/list`);
        } else {
          navigate(`/application/payment/fees/${orderId}`);
        }
        if (authInfo.role === "student") {
          outletAction("myProgress");
        }
      } else {
        common.notify("W", "Something went wrong");
      }
      setLoading(false);
    });
  };

  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(-1)}
            />
            <h3 className="inner-page-header-title mb-0 me-1">Application</h3>
          </div>

          <button
            className="col-md-3 ps-0 submit-app-btn btn-minus-margin et-cp"
            disabled={loading}
            onClick={onSubmit}
          >
            {loading ? "Processing..." : "Submit Application"}
            <img
              src={common.loadImg("double-circle-arrow.svg")}
              className="ms-3"
            />
          </button>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <Fragment>
            <div className="profile-notification">
              <div className="et-border-bottom">
                <div className="row mx-0">
                  <div className="col-md-9 d-flex justify-content-start">
                    <div className="p-3 ps-4 pe-4">
                      <div className="date-time d-flex justify-content-start">
                        Selected Course
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {result.map((i, k) => (
                <div className="et-border-bottom" key={k}>
                  <div className="row">
                    <div className="col-md-9">
                      <div className="row align-items-center">
                        <div className="col-md-2 p-4 ps-5">
                          <img
                            src={common.cloudImg(i.university?.logo)}
                            alt="univ-img"
                            className="univ-prev-img"
                          />
                        </div>
                        <div className="col-md-6 p-4 courseborder">
                          <div className="course-heading date-time">course</div>
                          <div className="course-font">{i.course.name}</div>
                        </div>
                        <div className="col-md-4 p-4 courseborder d-flex justify-content-between">
                          <div>
                            <div className="course-heading date-time">
                              Year & Month
                            </div>
                            <div className="course-font">
                              {i.year}{" "}
                              {common.getMonthList()[i.month - 1].label}
                            </div>
                          </div>
                          <div>
                            <img
                              src={common.loadImg("edit-btn.svg")}
                              alt=""
                              className="ms-1 me-2 cp"
                              onClick={() => {
                                setCourseId({
                                  id: i.course._id,
                                  tKey: `${id}:${i._id}`,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 pt-4 px-4 pb-4 semclass courseborder">
                      <div className="course-heading date-time">Course Fee</div>
                      <div className="course-font">
                        <b>{i.course.tuitionFee}</b> {i.course.currency} /
                        Annual
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <div className="detailshead">
                <p className="bussiness-reg-font">
                  Please check if all your personal details and documents are
                  accurate below
                </p>
                <Link
                  to={
                    authInfo.role === "student"
                      ? "/student/profile"
                      : `/agent/student/edit/${id}/personal_address/1`
                  }
                  className="text-dec-none"
                >
                  <p className="link-without-underline mb-0">
                    Edit Profile
                    <img
                      src={common.loadImg("arrow-right-circle.svg")}
                      alt=""
                      className="ms-2"
                    />
                  </p>
                </Link>
              </div>
            </div>

            <CommonStudentView
              id={id}
              formType="student"
              formModule="all"
              formPreview={true}
              onSuccess={() => navigate("/agent/student/list")}
              onlyView={true}
            />
            <div className="ref-heading-bg">
              <div className="profile-notification ">
                <div>
                  <div className="row d-flex align-items-center justify-content-between p-5">
                    <div className="col-md-6">
                      <div className="termscondition-font ">
                        I agree to the{" "}
                        <a href="#" className="terms-font">
                          Terms and Conditions{" "}
                        </a>
                        by clicking Submit
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                      {/* <button
                        className="btn-submit-application"
                        disabled={loading}
                        onClick={onSubmit}
                      >
                        {loading ? "Processing..." : "Submit Application"}
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-div"></div>
            <div className="greenshow-div"></div>
          </Fragment>
        )}
      </div>
      {courseId !== null && (
        <CoursePickup
          tId="ch"
          tKey={courseId.tKey}
          courseId={courseId.id}
          onClose={() => {
            setCourseId(null);
          }}
          onSuccess={() => {
            setCourseId(null);
            getInfo();
          }}
        />
      )}
    </Fragment>
  );
}
export default Preview;
