import { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Slider from "react-slick";
import "react-tooltip/dist/react-tooltip.css";
// includes
import { common } from "helpers";
// pages
import Five from "./register/five";
import Four from "./register/four";
import One from "./register/one";
import Seven from "./register/seven";
import Six from "./register/six";
import Three from "./register/three";
import Two from "./register/two";
import Agreement from "./register/agreement";

function Register() {
  // state
  const [init, setInit] = useState(false);
  const [step, setStep] = useState(1);
  const [viewA, setViewA] = useState(false);
  const [viewB, setViewB] = useState(false);
  const [tempData] = useState(common.tempDataGet());
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // effect

  useEffect(() => {
    if (tempData?.page) {
      setStep(tempData?.page);
    }
    setInit(true);
  }, []);

  // support
  const gotoStep = (val) => {
    if (step === 2 && val === 1) {
      setStep(1);
    }
    if (step === 4 && val === 3) {
      setStep(3);
    }
  };

  const onSuccess = (val) => {};
  const tooltip = [
    "Register as a Partner",
    "Otp verification",
    "Business info",
    "Reference check from the same industry",
    "Subscription",
    "Agreement",
  ];
  //render
  return (
    init && (
      <div className="container-fluid">
        {step == 1 && (
          <div className="row">
            <div className="col-md-8 reg-slider-wrap px-0">
              <div className="slider-container">
                <Slider {...common.signupSliderSettings()}>
                  <div>
                    <img
                      src={common.loadImg("slider/slider-img1.png")}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={common.loadImg("slider/slider-img2.png")}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={common.loadImg("slider/slider-img3.png")}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={common.loadImg("slider/slider-img4.png")}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={common.loadImg("slider/slider-img5.png")}
                      alt=""
                    />
                  </div>
                </Slider>
              </div>
            </div>
            <div className="col-md-4 reg-student-right-col reg-agent-border">
              <div className="right-content-width">
                <div className="signup-heading row">
                  <div className="col-md-6">
                    <p className="mb-0">Register</p>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    {Array(6)
                      .fill()
                      .map((i, k) => {
                        ++k;
                        return (
                          <div
                            key={k}
                            className="cp"
                            onClick={() => gotoStep(k)}
                          >
                            <p className="register-step-head mb-1 me-2">
                              <span
                                className={`dotbtn${step == 7 && k == 6 ? "" : k !== step ? 1 : ""}`}
                                data-title={tooltip[k - 1]}
                              >
                                0{k}
                              </span>
                            </p>
                          </div>
                        );
                      })}
                    {/* Tooltip */}
                    <p
                      id="tool-tip"
                      className="et-cp"
                      data-tooltip-content="This is Tooltip"
                    ></p>
                    <ReactTooltip
                      anchorId="tool-tip"
                      place={"bottom"}
                      style={{ zIndex: "999" }}
                    />
                  </div>
                </div>
                <div className="box-body p-0">
                  <One onSuccess={() => setStep(2)} />
                </div>
              </div>
              {/* <div className="double-border"></div> */}
            </div>
          </div>
        )}
        {step > 1 && step <= 7 && step != 5 && (
          <div className="row register-step-counter">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="row d-flex align-items-center mt-5">
                    <div className="col-md-4 col-sm-4 register-step-main-head reghead mt-4">
                      Register
                    </div>
                    <div className="col-md-4 col-sm-4 d-flex dottedcls steps-btn">
                      {Array(6)
                        .fill()
                        .map((i, k) => {
                          ++k;
                          console.log('k', k, step)
                          return (
                            <div
                              key={k}
                              className="cp"
                              onClick={() => gotoStep(k)}
                            >
                              <p className="register-step-head mb-1 me-2">
                                <span
                                  className={`dotbtn${step == 7 && k == 6 ? "" : k !== step ? 1 : ""}`}
                                  data-title={tooltip[k - 1]}
                                >
                                  0{k}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                      {/* Tooltip */}
                      <p
                        id="tool-tip"
                        className="et-cp"
                        data-tooltip-content="This is Tooltip"
                      ></p>
                      <ReactTooltip
                        anchorId="tool-tip"
                        place={"bottom"}
                        style={{ zIndex: "999" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className={`et-${step === 1 ? "show" : "hide"}`}>
          <One onSuccess={() => setStep(2)} />
        </div> */}
        {step === 2 && (
          <Two onSuccess={() => setStep(3)} onBack={() => setStep(1)} />
        )}
        {step === 3 && <Three onSuccess={() => setStep(4)} />}
        {step === 4 && (
          <Four onSuccess={() => setStep(5)} onBack={() => setStep(3)} />
        )}
        {step === 5 && <Five onSuccess={(e) => setStep(e)} />}
        {step === 6 && (
          <Six onSuccess={() => setStep(7)} onBack={() => setStep(5)} />
        )}
        {step === 7 && <Agreement onSuccess={() => setStep(8)} />}
        {step === 8 && <Seven />}
      </div>
    )
  );
}

export default Register;
