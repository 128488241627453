import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// includes
import FieldSelect from "elements/fieldSelect";
import { api, common } from "helpers";
import StatusBar from "elements/statusBar";

function View() {
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(null);
  const [result, setResult] = useState(null);
  const [salesPerson, setSalesPerson] = useState({});
  const [respAgent, setRespAgent] = useState({});
  const [verified, setVerified] = useState(false);

  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "agents",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setResult(res.data);
        // sales person
        const { salesPersonId, isVerified, coordinatorId } = res.data;
        setSalesPerson({
          label: salesPersonId?.salesPersonName,
          value: salesPersonId?.salesPersonId,
        });
        setRespAgent({
          label: coordinatorId?.email,
          value: coordinatorId?._id,
        });
        setVerified(isVerified);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const agentUpdate = (field, value) => {
    setLoading("status");
    let data = {
      url: "agent",
      method: "PUT",
      query: `/${id}`,
      body: {
        [field]: value,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Record has been updated");
      } else {
        common.notify("E", "Unable to update data please try again");
      }
      setLoading(null);
    });
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4">Agent View</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <div className="et-view-content-wrap">
                {/* Inner Content */}
                <div className="et-vc-info et-view-border-bottom">
                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Status & Activation
                    </h3>
                  </div>
                  <ul className="et-vc-list row">
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Active</p>
                        <p className="et-field-value">
                          {result.isActive ? "Yes" : "No"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Verify</p>
                        <p className="et-field-value">
                          {result.isVerified ? "Yes" : "No"}
                        </p>
                      </div>
                    </li>

                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Responsible Person</p>
                        <p className="et-field-value">
                          <FieldSelect
                            disabled={true}
                            api="common_sales_person"
                            query="?offset=0&limit=1000"
                            get="EmployeeName"
                            set="EmployeeId"
                            value={salesPerson}
                            placeholder="Select the responsible person"
                            render={"yes"}
                            clearable={false}
                            finder={false}
                            multi={false}
                            onSelect={(data) => {
                              setSalesPerson(data);
                              agentUpdate("salesPersonId", data.value);
                            }}
                          />
                        </p>
                      </div>
                    </li>

                    {result.isActive && verified && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Is Coordinator</p>
                          <p className="et-field-value">
                            {result.isPromotor ? "Yes" : "No"}
                          </p>
                        </div>
                      </li>
                    )}

                    {result.isActive && verified && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">
                            Responsible Coordinator
                          </p>
                          <p className="et-field-value">
                            <FieldSelect
                              disabled={true}
                              api="agents"
                              query={`?offset=0&limit=1000&agentId=${result._id}`}
                              get="email"
                              set="_id"
                              value={respAgent}
                              placeholder="Select the Coordinator"
                              render={"yes"}
                              clearable={true}
                              finder={false}
                              multi={false}
                              onSelect={(data) => {
                                setRespAgent(data);
                                agentUpdate(
                                  "coordinatorId",
                                  data?.value || null
                                );
                              }}
                            />
                          </p>
                        </div>
                      </li>
                    )}
                  </ul>

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Business Information
                    </h3>
                  </div>
                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Profile</p>
                        <p className="et-field-value mb-2">
                          {`${result.firstName} ${result.lastName}`}
                        </p>
                        <img
                          className="et-logo"
                          src={
                            result.logo
                              ? common.cloudImg(result.logo)
                              : common.loadImg("no_img.png")
                          }
                        />
                      </div>
                    </li>

                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Business Info</p>
                        <p className="et-field-value mb-2">
                          {`${result.businessName}`}
                        </p>
                        <img
                          className="et-logo"
                          src={
                            result.businessLogo
                              ? common.cloudImg(result.businessLogo)
                              : common.loadImg("no_img.png")
                          }
                        />
                      </div>
                    </li>

                    {[
                      {
                        name: "Email",
                        key: "email",
                        type: "string",
                      },
                      {
                        name: "Designation",
                        key: "designation",
                        type: "string",
                      },
                      {
                        name: "Business Address",
                        key: "businessAddress",
                        type: "string",
                      },
                      {
                        name: "Business Contact Number",
                        key: "businessContactNo",
                        type: "mobile",
                      },
                      {
                        name: "Business Registration Number",
                        key: "businessRegNumber",
                        type: "string",
                      },
                      {
                        name: "Your Recruitment Methods",
                        key: "recruitMethod",
                        type: "string",
                      },
                      {
                        name: "Number of students recruited for last year",
                        key: "noOfStudentRecruitLastYear",
                        type: "string",
                      },
                      {
                        name: "Number of staffs working",
                        key: "noOfStaffWorking",
                        type: "string",
                      },
                      {
                        name: "Other countries or branches",
                        key: "otherCountryBranch",
                        type: "string",
                      },
                      {
                        name: "Country",
                        key: "countryOfOperation",
                        type: "select",
                      },
                      {
                        name: "Province",
                        key: "province",
                        type: "select",
                      },
                      {
                        name: "City",
                        key: "city",
                        type: "select",
                      },
                    ].map((i, k) => (
                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                        key={k}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">{i.name}</p>
                          <p className="et-field-value">
                            {i.type === "mobile" ? (
                              <span>
                                {`${result.mobileCode} ${result.businessContactNo}`}
                              </span>
                            ) : (
                              common.readable(i.type, result[i.key])
                            )}
                          </p>
                        </div>
                      </li>
                    ))}

                    <li className="col-md-8 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Description</p>
                        <p className="et-field-value">
                          {result.description || "-"}
                        </p>
                      </div>
                    </li>
                  </ul>

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">Document</h3>
                  </div>

                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-6 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Business Registration</p>
                        {(result?.businessRegDocs == null || result?.businessRegDocs?.length === 0) && (
                          <p className="et-vc-label">No document found</p>
                        )}
                        {result?.businessRegDocs != null && result?.businessRegDocs?.length > 0  && result?.businessRegDocs.map((iB, kB) => (
                          <div key={kB} className="row mb-3">
                            <div className="et-field-value col-md-9">
                              <span
                                className="et-link"
                                onClick={() => common.openCloudUrl(iB)}
                              >
                                {common.fileName(iB)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>

                    <li
                      className={`col-md-6 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Proof of Identity</p>
                        {(result?.proofIdentityDocs == null || result?.proofIdentityDocs?.length === 0) && (
                          <p className="et-vc-label">No document found</p>
                        )}
                        {result?.proofIdentityDocs != null && result?.proofIdentityDocs?.length > 0 &&result.proofIdentityDocs.map((iB, kB) => (
                          <div key={kB} className="row mb-3">
                            <div className="et-field-value col-md-9">
                              <span
                                className="et-link"
                                onClick={() => common.openCloudUrl(iB)}
                              >
                                {common.fileName(iB)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>
                  </ul>

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">Reference</h3>
                  </div>

                  {result.reference.length === 0 && (
                    <div className="et-vc--no-info d-flex justify-content-between align-items-center et-view-border-bottom">
                      <div className="et-vc-left">
                        <p className="et-vc-label">No reference found</p>
                      </div>
                    </div>
                  )}

                  {result.reference.map((i, k) => (
                    <Fragment key={k}>
                      <div className="et-vc-step-info d-flex align-items-center justify-content-between et-view-border-bottom">
                        <div>
                          <span className="et-vc-step me-3">0{++k}</span>
                          <span className="et-vc-step-title">Reference</span>
                        </div>
                      </div>
                      <ul className="et-vc-list row">
                        {[
                          {
                            name: "Full Name",
                            key: "fullName",
                            type: "string",
                          },
                          {
                            name: "Designation",
                            key: "designation",
                            type: "string",
                          },
                          {
                            name: "Email Address",
                            key: "email",
                            type: "string",
                          },
                        ].map((cItem, cKey) => (
                          <li
                            className={`col-md-4 et-view-border-bottom et-view-border-right`}
                            key={cKey}
                          >
                            <div className="et-list-inner">
                              <p className="et-field-name">{cItem.name}</p>
                              <p className="et-field-value">
                                {common.readable(cItem.type, i[cItem.key])}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ))}

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Subscription & Payment
                    </h3>
                  </div>
                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Plan</p>
                        <p className="et-field-value">
                          {result?.plans ? result?.plans?.title : "-"}
                        </p>
                      </div>
                    </li>
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Payment Id</p>
                        <p className="et-field-value">
                          {result?.payments
                            ? result?.payments.transitionId
                            : "-"}
                        </p>
                      </div>
                    </li>
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Expire Date</p>
                        <p className="et-field-value">
                          {result?.payments
                            ? common.dateFormat(
                                result.payments.subscribeEnd,
                                "A"
                              )
                            : "-"}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading !== null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
export default View;
