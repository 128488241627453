import React from "react";
import Modal from "react-modal";
import { common } from "helpers";

function ModalConfirm(props) {
  return (
    <Modal
      isOpen={true}
      style={common.modalStyle(40)}
      ariaHideApp={false}
      contentLabel="Modal"
    >
      <div className="p-4">
        <div className="et-modal-header d-flex justify-content-between align-items-center">
          <h2 className="et-modal-title">Confirmation</h2>
          <button className="et-modal-close" onClick={props?.onNo}>
            <img src={common.loadImg("modal-close-icn.svg")} className="cp" />
          </button>
        </div>
        <div className="et-modal-body text-center">
          <div className="mb-4">
            <img
              src={common.loadImg("delete-confirmation-icn.svg")}
              alt="Delete confirmation Icon"
            />
          </div>
          <h3>Are you sure you want to do this action?</h3>
        </div>

        <div className="et-modal-footer p-3">
          <div className="d-flex justify-content-center">
            <button
              className="register-step-btn no-btn me-2"
              onClick={props?.onNo}
            >
              No
            </button>
            <button
              className="register-step-btn"
              onClick={props?.onYes}
              disabled={props?.loading ?? false}
            >
              {props?.loading ? "Wait...." : "Yes"}
            </button>
          </div>
        </div>
      </div>
      <div className="shadow-div"></div>
      <div className="greenshow-div"></div>
    </Modal>
  );
}
export default ModalConfirm;
