import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { common } from "helpers";

function Seven() {
  const navigate = useNavigate();
  // effect
  useEffect(() => {
    common.tempDataRemove();
  }, []);

  // submit handler
  const onSubmit = () => {
    common.localDataSet({
      alert: "Registration has been successfull",
    });
    navigate("/login");
  };
  //render
  return (
    <Fragment>
      <div className="row inner-page-bg py-5">
        <div className="col-lg-12 mx-auto"></div>
        <Modal show={true} size={"lg"}>
          <Modal.Body>
            <div className="payment-success-modal-box">
              <div className="payment-info-wrap">
                <div className="payment-success-msg-txt text-center">
                  <img
                    src={common.loadImg("payment-tick-icn.svg")}
                    alt=""
                    className="mb-3"
                  />
                  <h1>Your Subscription was Successful</h1>
                  <h5>
                    Thank you for your subscription, we are currently verifying
                    you account and will get back to you soon
                  </h5>
                </div>
                {true === false && (
                  <table className="table table-borderless payment-history-table">
                    <tbody>
                      <tr>
                        <th>Amount Paid</th>
                        <td align="right">2200 USD</td>
                      </tr>
                      <tr>
                        <th>Transaction ID</th>
                        <td align="right">#1234567890</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div className="text-center">
                <button className="btn btn-signin" onClick={onSubmit}>
                  Continue
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
}
export default Seven;
