import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import socketIO from "socket.io-client";
import { config } from "./helpers/config";
// includes
// layouts
import FrontLayout from "./layouts/frontLayout";
import InnerLayout from "./layouts/innerLayout";
// front
import ForgotPassword from "pages/front/forgotPassword";
import OtpVerification from "pages/front/otpVerification";
import Register from "pages/front/register";
import ResetPassword from "pages/front/resetPassword";
import SignIn from "pages/front/signIn";
// student
import StudentHome from "pages/student/home";
import StudentProfile from "pages/student/profile";
import StudentProgress from "pages/student/progress";
import StudentRegister from "pages/student/register";
// application
import ApplicationCoursePayment from "pages/application/coursePayment";
import ApplicationFeesPayment from "pages/application/feesPayment";
import ApplicationInfo from "pages/application/info";
import ApplicationList from "pages/application/list";
import ApplicationPreview from "pages/application/preview";
import ApplicationPreviewOrder from "pages/application/previewAf";
import ApplicationProfile from "pages/application/profile";
import ApplicationProgress from "pages/application/progress";
import ApplicationStatus from "pages/application/status";
// agent
import AgentAccountInfo from "pages/agent/account/info";
import AgentPayment from "pages/agent/account/payment";
import AgentConnectList from "pages/agent/connect/list";
import AgentHome from "pages/agent/home";
import PromoForm from "pages/agent/promocode/form";
import PromoList from "pages/agent/promocode/list";
//promoters
import PromotersAppList from "pages/agent/promoters/list/application";
import PromotersList from "pages/agent/promoters/list/index";
import PromotersStudentList from "pages/agent/promoters/list/student";
import PromotersView from "pages/agent/promoters/view";
import PromotersAppView from "pages/agent/promoters/view/application";
import PromotersStudentView from "pages/agent/promoters/view/student";

import AgentRegister from "pages/agent/register";
import AgentStudentAdd from "pages/agent/student/add";
import AgentStudentEdit from "pages/agent/student/edit";
import AgentStudentList from "pages/agent/student/list";
import AgentStudentView from "pages/agent/student/view";

import TermsAndConditions from "./elements/TermsAndConditions";

import AgentMemberAdd from "pages/agent/member/add";
import AgentMemberEdit from "pages/agent/member/edit";
import AgentMemberList from "pages/agent/member/list";
import AgentMemberView from "pages/agent/member/view";

// course
import CourseFinder from "pages/course/finder";
import CourseInfo from "pages/course/info";
// university
import UniverSityInfo from "pages/university/info";

//Setting
import Settings from "pages/settings/view";

const socket = socketIO.connect(config?.api?.socket_url);

function App() {
  return (
    <Router>
      {/* Front */}
      <Routes>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/" element={<FrontLayout />}>
          {/* <Route path="/" element={<Home />} /> */}
          {config.type === "AGENT" ? (
            <React.Fragment>
              <Route path="/" element={<AgentRegister />} />
              <Route path="/register/agent" element={<AgentRegister />} />
            </React.Fragment>
          ) : (
            <Route path="/" element={<StudentRegister />} />
          )}
          <Route path="/register/student/:type" element={<StudentRegister />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/password/forgot" element={<ForgotPassword />} />
          <Route path="/password/reset" element={<ResetPassword />} />
          <Route path="/otp/verify" element={<OtpVerification />} />
          <Route path="/register" element={<Register />} />
        </Route>

        {/* student */}
        <Route
          path="/student"
          element={<InnerLayout role="student" socket={socket} />}
        >
          <Route path="home" element={<StudentHome />} />
          <Route path="profile" element={<StudentProgress />} />
          <Route path="profile/:type" element={<StudentProfile />} />
        </Route>

        {/* Agent */}
        <Route
          path="/agent"
          element={<InnerLayout role="agent" socket={socket} />}
        >
          <Route path="home" element={<AgentHome socket={socket} />} />
          <Route path="student/add" element={<AgentStudentAdd />} />
          <Route
            path="student/edit/:id/:type/:from"
            element={<AgentStudentEdit />}
          />
          <Route path="student/view/:id" element={<AgentStudentView />} />
          <Route path="student/list" element={<AgentStudentList />} />
          <Route path="connect/list" element={<AgentConnectList />} />
          <Route path="account/info" element={<AgentAccountInfo />} />
          <Route path="payment/:id" element={<AgentPayment />} />
          <Route path="promo/list" element={<PromoList />} />
          <Route path="promo/list/add" element={<PromoForm />} />
          <Route path="promo/list/edit/:id" element={<PromoForm />} />
          <Route path="promo/list" element={<PromoList />} />
          <Route path="promo/list/add" element={<PromoForm />} />
          <Route path="promo/list/edit/:id" element={<PromoForm />} />
          <Route path="promoters/list" element={<PromotersList />} />
          <Route
            path="promoters/application/:agentID"
            element={<PromotersAppList />}
          />
          <Route
            path="promoters/application/info/:id"
            element={<PromotersAppView socket={socket}/>}
          />
          <Route
            path="promoters/student/:agentID"
            element={<PromotersStudentList />}
          />
          <Route
            path="promoters/student/info/:stdID"
            element={<PromotersStudentView />}
          />
          <Route path="promoters/view/:id" element={<PromotersView />} />
          <Route path="member/add" element={<AgentMemberAdd />} />
          <Route
            path="member/edit/:id"
            element={<AgentMemberEdit />}
          />
          <Route path="member/view/:id" element={<AgentMemberView />} />
          <Route path="member/list" element={<AgentMemberList />} />
        </Route>

        {/* Application */}
        <Route
          path="/application"
          element={<InnerLayout role="common" socket={socket} />}
        >
          <Route path="list" element={<ApplicationList socket={socket} />} />
          <Route path="preview/:id" element={<ApplicationPreview />} />
          <Route
            path="preview/order/:id"
            element={<ApplicationPreviewOrder />}
          />
          <Route
            path="payment/fees/:idA"
            element={<ApplicationFeesPayment />}
          />
          <Route
            path="status/:id"
            element={<ApplicationStatus socket={socket} />}
          />
          <Route path="info/:id" element={<ApplicationInfo />} />
          <Route
            path="payment/course/:idA"
            element={<ApplicationCoursePayment />}
          />
          <Route path="profile/:id" element={<ApplicationProgress />} />
          <Route path="profile/:id/:type" element={<ApplicationProfile />} />
        </Route>

        {/* Course */}
        <Route
          path="/course"
          element={<InnerLayout role="common" socket={socket} />}
        >
          <Route path="finder/:tId/:tKey" element={<CourseFinder />} />
          <Route path="info/:id/:tId/:tKey" element={<CourseInfo />} />
        </Route>

        {/* University */}
        <Route
          path="/university"
          element={<InnerLayout role="common" socket={socket} />}
        >
          <Route path="info/:id/:tId/:tKey" element={<UniverSityInfo />} />
        </Route>

        {/* Settings */}
        <Route
          path="/settings"
          element={<InnerLayout role="common" socket={socket} />}
        >
          <Route path="" element={<Settings />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
