import React, { Fragment, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// layouts
import FrontHeader from "./frontHeader";
// includes
import { common } from "helpers";

const FrontLayout = (props) => {
  const navigate = useNavigate();
  // state
  const [isAuth] = useState(common.checkAuth());
  // effect
  useEffect(() => {
    if (isAuth) {
      common.redirect(
        common.getAuth()?.role === "student" ? "student/home" : "agent/home"
      );
    }
  }, [isAuth]);
  return (
    !isAuth && (
      <Fragment>
        <FrontHeader />
        <Outlet {...props} />
      </Fragment>
    )
  );
};

export default FrontLayout;
