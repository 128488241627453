import React, { Fragment, useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
// pages
import CommonStudentView from "includes/studentView";
// includes
import { common, types } from "helpers";
import StatusBar from "elements/statusBar";

function Profile() {
  const { type } = useParams();
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  // render
  return (
    <>
      {userInfoStatus !== "success" ? (
        <StatusBar status={userInfoStatus} />
      ) : (
        <Fragment>
          <div className="title-wrap sticky-top">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={common.loadImg("back-icn.svg")}
                  className="ed-cp pe-4"
                  alt=""
                  onClick={() => navigate("/student/profile")}
                />
                {/* <h3 className="inner-page-header-title mb-0 me-1"></h3> */}
                <h3 className="inner-page-header-title mb-0">
                Profile - {types.profileHeader[type]}
                </h3>
              </div>
              <div>
                <div
                  className="report-issue text-dec-none ed-cp"
                  onClick={() => navigate("/student/profile")}
                >
                  <img
                    src={common.loadImg("close-icn.svg")}
                    alt=""
                    className="ms-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrap">
            <CommonStudentView
              id=""
              formModule={type}
              formPreview={false}
              onSuccess={() => navigate("/student/profile")}
            />
          </div>
        </Fragment>
      )}
    </>
  );
}
export default Profile;
