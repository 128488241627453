import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// includes
import { common } from "helpers";
// pages
import CommonStudentForm from "includes/studentForm";

function Form() {
  const navigate = useNavigate();
  const tooltip = [
    "",
    "General Information",
    "Educational Information",
    "Background Information",
    "Test Score",
    "Work History",
    "Document Attach",
  ];
  return (
    <>
      <Fragment>
        <div className="inner-page-header-agent title-wrap py-0">
          <Row className="row d-flex justify-content-between align-items-center">
            <Col lg={4} md={5} className="application-heading">
              <Link to="/agent/student/list">
                <img
                  src={common.loadImg("back-icn.svg")}
                  alt=""
                  className="et-cp me-3"
                />
              </Link>
              Add New Student
            </Col>
            <Col
              lg={4}
              md={4}
              className="d-flex align-items-center justify-content-center dottedclsnew"
            >
              {Array(6)
                .fill()
                .map((i, k) => {
                  ++k;
                  return (
                    <div key={k} className="cp">
                      <p className="register-step-head mb-1 me-2">
                        <span
                          className={`dotbtn${k !== 1 ? 1 : ""}`}
                          data-title={tooltip[k]}
                        >
                          0{k}
                        </span>
                      </p>
                    </div>
                  );
                })}
            </Col>
            <Col lg={4} md={3} className="endtext">
              {/* <Link to="#" className="report-issue">
                  Save and Close
                  <img
                    src={common.loadImg("close-icn.svg")}
                    alt="arrow"
                    className="et-cp ms-1"
                  />
                </Link> */}
            </Col>
          </Row>
        </div>
        <div className="content-wrap">
          <CommonStudentForm
            studentId=""
            formModule="personal_address"
            formIndex="all"
            formAddNew={true}
            formModal={false}
            formType="agentNew"
            onSuccess={(id) =>
              navigate(`/agent/student/edit/${id}/education_college/0`)
            }
          />
        </div>
      </Fragment>
    </>
  );
}

export default Form;
