import { Fragment, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
// includes
import ModalConfirm from "elements/modalConfirm";
import ModalInfo from "elements/modalInfo";
import StatusBar from "elements/statusBar";
import ConnectToAgent from "includes/connectToAgent";
import CoursePickup from "includes/coursePickup";
import { api, common, message } from "../../helpers";

function Home() {
  // const
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [loading, setLoading] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [myModal, setMyModal] = useState(null);
  const [infoModal, setInfoModal] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const authInfos = common.getAuth();

  useEffect(() => {
    getStudent();
  }, []);

  const getStudent = () => {
    let data = {
      url: "student_profile",
      method: "GET",
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setCurrentUser(res.data);
      }
    });
  };

  // handler
  const previewApplication = (cl) => {
    if (userInfo?.progress?.total != 100) {
      setInfoModal({
        title: "Incomplete Profile",
        message: [
          <div>
            <div className="et-modal-body text-center">
              <div className="mb-4">
                <img
                  src={common.loadImg("course-selection-icn1.svg")}
                  alt="Course Selection Icon"
                />
              </div>
              <h4>
                You have not completed your profile please complete and select
                the course
              </h4>
            </div>
            <div className="et-modal-footer p-3">
              <div className="d-flex justify-content-center">
                <button
                  className="register-step-btn"
                  onClick={() => navigate("/student/profile")}
                >
                  Go to Profile
                </button>
              </div>
            </div>
          </div>,
        ],
        footerHide: true,
      });
    } else {
      if (cl) {
        navigate(`/application/preview/${authInfo._id}`);
      } else {
        setInfoModal({
          title: "Missing Course",
          message: [
            <div>
              <div className="et-modal-body text-center">
                <div className="mb-4">
                  <img
                    src={common.loadImg("course-selection-icn1.svg")}
                    alt="Course Selection Icon"
                  />
                </div>
                <h4>Please select a course to continue</h4>
              </div>
              <div className="et-modal-footer p-3">
                <div className="d-flex justify-content-center">
                  <button
                    className="register-step-btn"
                    onClick={() => navigate("/course/finder/p/0")}
                  >
                    Go to Course Finder
                  </button>
                </div>
              </div>
            </div>,
          ],
          footerHide: true,
        });
      }
    }
  };
  const deleteCourse = () => {
    setLoading(true);
    let data = {
      url: "course_pickup",
      method: "DELETE",
      query: `/${authInfo._id}/${deleteData.id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", message.cb);
      } else {
        common.notify("E", message.ca);
      }
      setDeleteData(null);
      setLoading(false);
      outletAction("myProgress");
    });
  };

  return (
    <div className="content-wrap home-wrap">
      <div className="home-inner-wrap">
        {userInfoStatus !== "success" ? (
          <StatusBar status={userInfoStatus} />
        ) : (
          <Fragment>
            <div>
              <p className="student-home-title">Hi {authInfos?.firstName}</p>
            </div>
            <div className="profile-notification mt-3">
              <div>
                <div className="card-title-wrap">
                  <p className="filter-cards-title mb-0">Your Profile</p>
                </div>
                <div
                  className={
                    userInfo?.progress?.total != 100
                      ? "stu-profile"
                      : "profile-completed-bg"
                  }
                >
                  <div className="row px-3">
                    {userInfo?.progress?.total != 100 ? (
                      <div className="col-md-9 d-flex align-items-center">
                        <img
                          src={common.loadImg("profile-green.svg")}
                          className="me-3 ms-1"
                          alt="notify-icn"
                        />
                        <div className="p-2 mt-2">
                          <p className="bussiness-reg-font">
                            Please complete your profile and details for an
                            easier application Process
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-9 d-flex align-items-center">
                        <img
                          src={common.loadImg("complete-profile-tick-icn.svg")}
                          className="me-3 ms-1"
                          alt="notify-icn"
                        />
                        <div className="p-2 mt-2">
                          <p className="bussiness-reg-font profile-completed-txt">
                            Profile completed
                          </p>
                        </div>
                      </div>
                    )}
                    {userInfo?.progress?.total != 100 ? (
                      <div className="col-md-3 complete-padding complete-border res-border-top">
                        <div className="row align-items-center">
                          <div className="col-9 col-md-8">
                            {/* {userInfo?.progress?.total != 100 && ( */}
                            <Link
                              to="/student/profile"
                              className="complete-profile-lnk"
                            >
                              Complete Profile
                            </Link>
                            {/* )} */}

                            <div className="d-flex align-items-center percent-font my-profile-percent mt-1">
                              <span>{userInfo?.progress?.total}%</span>
                              <ProgressBar
                                now={userInfo?.progress?.total}
                                className="progressbar-height ms-1"
                              />
                            </div>
                          </div>
                          <div className="col-3 col-md-4 d-flex">
                            <Link
                              to={
                                userInfo?.progress?.total === 100
                                  ? "/course/finder/p/0"
                                  : "/student/profile"
                              }
                            >
                              <img
                                src={common.loadImg("blue-arrow-right.svg")}
                                className="ms-2"
                                alt="arrow"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-3 complete-padding complete-border res-border-top">
                        <div className="row  align-items-center ">
                          <div className="col-9 col-md-8">
                            <Link
                              to="/student/profile"
                              className="complete-profile-lnk"
                            >
                              Edit Profile
                            </Link>
                          </div>
                          <div className="col-3 col-md-4 d-flex">
                            <Link to="/student/profile">
                              <img
                                src={common.loadImg("blue-arrow-right.svg")}
                                className="ms-2"
                                alt="arrow"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="profile-notification mt-3">
              {userInfo.course.picked.length === 0 ? (
                <div className="row px-3">
                  <div className="card-title-wrap">
                    <p className="filter-cards-title mb-0">Your Course</p>
                  </div>
                  <div className="col-md-9 d-flex align-items-center justify-content-start">
                    <img
                      src={common.loadImg("application-green.svg")}
                      className="me-3 ms-1"
                      alt="application-icn"
                    />
                    <div className="mt-2 p-2">
                      <p className="bussiness-reg-font">
                        Please select your course and proceed with your
                        university application
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3 select-padding  complete-border res-border-top">
                    <div className="row d-flex align-items-center">
                      <div className="col-9 col-md-8">
                        <Link
                          to="/course/finder/p/0"
                          className="complete-profile-lnk"
                        >
                          Select Course
                        </Link>
                      </div>
                      <div className="col-3 col-md-4 d-flex select-course-btn">
                        <Link to="/course/finder/p/0">
                          <img
                            src={common.loadImg("blue-arrow-right.svg")}
                            className="ms-2"
                            alt="arrow"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="profile-notification mt-3">
                  <div className="row px-3">
                    <div className="card-title-wrap">
                      <p className="filter-cards-title mb-0">Your Course</p>
                    </div>
                    {userInfo.course.picked.map((i, k) => (
                      <div className="row card-border-bottom p-0 m-0" key={k}>
                        <div className="col-md-3 col-12 d-flex align-items-center justify-content-start">
                          <img
                            src={common.loadImg("application-green.svg")}
                            className="me-3 ms-1"
                            alt="application-icn"
                          />
                          <div className="p-2">
                            <img
                              className="cp uni-logo"
                              src={common.cloudImg(i.university?.logo)}
                              alt={i.university?.name}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-12 d-flex pt-2">
                          <div>
                            <p className="course-heading mb-0">Course</p>
                            <p className="user-experience mb-0">
                              {i.course.name}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 col-12 d-flex pt-2">
                          <div>
                            <p className="course-heading mb-0">
                              Intake Year & Month
                            </p>
                            <p className="user-experience mb-0">
                              {i.year}{" "}
                              {common.getMonthList()[i.month - 1].label}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 col-12 complete-border crs-links py-4">
                          {/* <img
                              src={common.loadImg("edit-btn.svg")}
                              alt=""
                              className="mx-2 et-cp"
                              onClick={() => {
                                setCourseId({
                                  id: i.course._id,
                                  tKey: `${authInfo._id}:${i._id}`,
                                });
                              }}
                            /> */}
                          <a
                            className="complete-profile-lnk et-cp me-4"
                            onClick={() => {
                              setCourseId({
                                id: i.course._id,
                                tKey: `${authInfo._id}:${i._id}`,
                              });
                            }}
                          >
                            Edit Intake
                          </a>
                          <a
                            className="complete-profile-lnk et-cp"
                            onClick={() => setDeleteData({ id: i._id })}
                          >
                            Remove
                          </a>
                        </div>
                      </div>
                    ))}
                    {userInfo.course.picked.length < 3 && (
                      <div className="p-3">
                        <Link
                          to="/course/finder/p/0"
                          className="d-flex justify-content-center align-items-center add-course-link"
                        >
                          <img
                            src={common.loadImg("plus-icn.svg")}
                            className="me-2"
                            alt="plus-icn"
                          />
                          <p className="mb-0">Add Course</p>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="res-button-apply d-flex justify-content-end mt-4 p-0">
              {/* <Link
              to={{pathname:`/application/preview/${authInfo._id}`}}
              className="text-dec-none"
            > */}
              <button
                onClick={() =>
                  previewApplication(userInfo.course.picked.length)
                }
                className="btn-signin d-flex justify-content-between align-items-center"
              >
                <span className="ms-4">Apply Now</span>
                <img
                  src={common.loadImg("right-arrow-white-icn.svg")}
                  className="me-3"
                  alt="promotion-icn"
                />
              </button>
              {/* </Link> */}
            </div>
            {(currentUser?.agentId == null ||
              currentUser?.agentId == undefined) && (
              <>
                {userInfo?.agent ? (
                  <div className="connect-to-agent-wrap mt-4">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="d-flex">
                          <div className="connect-agent-img px-5 align-self-center">
                            <img
                              src={
                                userInfo.agent?.businessLogo
                                  ? common.cloudImg(userInfo.agent.businessLogo)
                                  : common.loadImg("agent-icn.svg")
                              }
                              alt="Agent"
                            />
                          </div>
                          <div className="connect-agent-content selected-agent-info pe-5 py-4 align-self-center">
                            <div className="mb-3">
                              <h3 className="agent-name mb-0">
                                {userInfo.agent.businessName}
                              </h3>
                              <p className="agent-location">
                                {userInfo.agent.countryOfOperation?.name} |{" "}
                                {userInfo.agent.province?.name},{" "}
                                {userInfo.agent.city?.name}
                              </p>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                              <div className="me-3">
                                <a
                                  href={`mailto:${userInfo.agent?.email}`}
                                  target="_blank"
                                >
                                  <img
                                    className="connected-agent-email"
                                    src={common.loadImg("mail-icon.png")}
                                    alt="Mail"
                                  />
                                </a>
                              </div>
                              <div className="me-3">
                                <a
                                  href={`tel:${
                                    "+" + userInfo.agent?.businessContactNo
                                  }`}
                                >
                                  <img
                                    className="connected-agent-call"
                                    src={common.loadImg("call-icon.png")}
                                    alt="Call"
                                  />
                                </a>
                              </div>
                              <div className="me-3">
                                <ReactWhatsapp
                                  number="+447300099636"
                                  message="Hi Pickauni!"
                                  className="whatsapp-border"
                                >
                                  <img
                                    className="connected-agent-whatsapp"
                                    src={common.loadImg("whatsapp-icon.png")}
                                    alt="Whatsapp"
                                  />
                                </ReactWhatsapp>
                              </div>
                            </div>
                            <div className="agent-desc">
                              <p>{userInfo.agent.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 align-self-center">
                        <div className="px-4">
                          <button
                            className="btn-connect-now w-100 d-flex justify-content-between align-items-center"
                            onClick={() => setMyModal("agentConnect")}
                          >
                            <span>Connected</span>
                            <img
                              src={common.loadImg("right-arrow-green-icn.svg")}
                              alt="promotion-icn"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="connect-to-agent-wrap ed-connect-to-agent mt-4">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="d-flex">
                          <div className="connect-agent-img px-4 align-self-end">
                            <img
                              src={common.loadImg("connect-to-agent-icn.svg")}
                              className="me-3"
                              alt="connect to agent"
                            />
                          </div>
                          <div className="connect-agent-content ps-2 pe-5 py-5 align-self-center">
                            <h3>Need help applying to study abroad?</h3>
                            <p>
                              Speak with a trusted and experienced advisor who
                              can help guide you along your international
                              education journey.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 align-self-center">
                        <div className="px-4">
                          <button
                            className="btn-connect-now w-100 d-flex justify-content-between align-items-center"
                            onClick={() => setMyModal("agentConnect")}
                          >
                            <span>Connect Now</span>
                            <img
                              src={common.loadImg("right-connect-now-icn.svg")}
                              alt="promotion-icn"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Fragment>
        )}
      </div>
      {deleteData !== null && (
        <ModalConfirm
          loading={loading}
          onYes={() => deleteCourse()}
          onNo={() => setDeleteData(null)}
        />
      )}
      {infoModal !== null && (
        <ModalInfo data={infoModal} onYes={() => setInfoModal(null)} />
      )}

      {myModal === "agentConnect" && (
        <ConnectToAgent
          userInfo={userInfo}
          onClose={() => {
            outletAction("myProgress");
            setMyModal(null);
          }}
        />
      )}

      {courseId !== null && (
        <CoursePickup
          tId="ch"
          tKey={courseId.tKey}
          courseId={courseId.id}
          onClose={() => {
            setCourseId(null);
          }}
          onSuccess={() => {
            setCourseId(null);
          }}
        />
      )}
    </div>
  );
}
export default Home;
