import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// includes
import AlertBar from "elements/alertBar";
import StatusBar from "elements/statusBar";
import VerifyModal from "elements/modalNotVerified";
import { api, common } from "helpers";
import { config } from "../../helpers/config";
import FireBase from "../../helpers/firebase";

export default function SignIn() {
  // init
  const navigate = useNavigate();
  // state
  const [loading, setloading] = useState(false);
  const [isView, setIsView] = useState(false);
  const [localData] = useState(common.localDataGet());
  const [isModal, setIsModal] = useState(null);
  const [error, setError] = useState({ email: "", password: "" });
  const [deviceToken, setDeviceToken] = useState(null);
  const [queryParams] = useSearchParams();
  let qry = queryParams.get("id");

  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
      ],
    },
    password: {
      validate: [
        "req#Password is required!",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    FireBase.getDeviceToken(setDeviceToken);
    common.tempDataRemove();
    if (qry) {
      Login();
    }
  }, []);

  // handler
  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      Login();
    }
  };

  // Api
  const Login = () => {
    setloading(true);

    let payload = fields;
    if (qry) {
      let data = common.deCrypt(decodeURIComponent(qry));
      data = JSON.parse(data);
      console.log("dec data -> ", data);
      payload.email = data.u;
      payload.password = data.p;
    }
    payload["webDevToken"] = deviceToken;
    if (config.type === "AGENT") {
      payload["userType"] = "AGENT";
    } else {
      payload["userType"] = "STUDENT";
    }
    // api call
    let data = {
      url: "ids_login",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let data = res.data;
        if (data.role === "student") {
          common.localSet("authData", common.crypt(data, true));
          navigate("/student/home");
        } else if (data.role === "sub_agent") {
          common.localSet("authData", common.crypt(data, true));
          navigate("/agent/home");
        } else {
          let page = null;
          let planId = null;
          if (!data.businessContactNo && !data?.businessName) {
            page = 3;
          }
          // else if (
          // data.businessRegDocs.length === 0 //&&
          //data.proofIdentityDocs.length === 0
          // ) {
          // page = 4;
          // }
          else if (!data?.payments) {
            page = 5;
          } else if (!data?.agreement) {
            page = 7;
          }
          if (page) {
            common.tempDataSet({
              page,
              planId,
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
            });
            navigate("/register/agent/");
          } else {
            common.localSet("authData", common.crypt(data, true));
            navigate("/agent/home");
            // setIsModal("notVerified");
          }
        }
      } else if (res.status === 500) {
        if (res.data.code === "incorrect_password") {
          setError((prev) => ({ ...prev, password: res.data.error }));
        } else {
          setError((prev) => ({ ...prev, email: res.data.error }));
        }
        setloading(false);
      } else {
        common.notify("E", "Something went wrong, please try again");
      }
      setloading(false);
    });
  };

  // support
  const resetError = (name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <div className="content-wrap-pt">
      <div className="et-page-wrap py-5 signin-wrap">
        {qry ? (
          <div className="row">
            <div className="col-md-5 mx-auto">
              <div className="signin-loader-wrap">
                <img
                  src={common.loadImg("edutech-loader.gif")}
                  className="loader-img"
                  alt="application-icn"
                />
                <h2 style={{ textAlign: "center" }}>
                  Loading please wait few seconds...
                </h2>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-5 mx-auto">
              <div className="box-header">
                <p className="title">Sign in to Your Account</p>
              </div>
              <div className="box-body">
                {localData?.alert && (
                  <AlertBar status="success" message={localData?.alert} />
                )}
                <div>
                  <label className="form-label">Email Address</label>
                  <div className="et-form-row">
                    <input
                      type="email"
                      name="email"
                      className="form-control input-email"
                      maxLength={40}
                      value={fields.email}
                      onChange={(e) => {
                        resetError("email");
                        handleChange(e);
                      }}
                    />
                    <div className="email-fieldcheckbox checkbox-design">
                      <div className={"input-tick-active"}></div>
                    </div>
                    <p className="error-txt">
                      {error.email ? error.email : errors.email}
                    </p>
                  </div>
                  <label className="form-label mt-3">Password</label>
                  <div className="et-form-row">
                    <input
                      type={isView ? "text" : "password"}
                      name="password"
                      className="form-control"
                      maxLength={15}
                      onChange={(e) => {
                        resetError("password");
                        handleChange(e);
                      }}
                      value={fields.password}
                    />
                    <div className=" email-fieldcheckbox checkbox-design">
                      <div className="input-tick-active"></div>
                    </div>
                    <div className="pwd-eye" onClick={() => setIsView(!isView)}>
                      <img
                        src={common.loadImg(
                          isView ? "password_show.svg" : "password_hide.svg"
                        )}
                        alt="show"
                        className="et-cp"
                      />
                    </div>
                    <p className="error-txt">
                      {error.password ? error.password : errors.password}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex">
                      <div className="checkbox-design d-flex me-3">
                        <input
                          id="rememberLogin"
                          className="me-3"
                          type="checkbox"
                          name="rememberme"
                        />
                        <label
                          className="rememberlogin-text et-cp"
                          htmlFor="rememberLogin"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div>
                      <Link
                        to="/password/forgot"
                        className="rememberlogin-text"
                      >
                        Forgot Password
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className="btn-default"
                      disabled={loading}
                      onClick={onSubmit}
                    >
                      {loading ? "Processing" : "Sign In"}
                    </button>
                  </div>
                  <div className="et-page-wrap-border mt-4 mb-3">
                    <div className="d-flex justify-content-center rememberlogin-text mt-3">
                      Don’t have an account ?
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between mt-4 mb-2 signin-btn-wrap">
                  <Link to="/register/student/direct" className="me-3">
                    <button className="et-btn-outline">
                      Register as a Student
                    </button>
                  </Link>
                  <Link to="/register/agent">
                    <button className="et-btn-outline">
                      Register as a Partner
                    </button>
                  </Link>
                </div> */}
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="mt-3 mb-2 signin-btn-wrap">
                      <Link to="/" className="me-3">
                        <button className="et-btn-outline btn-reg">
                          Register
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shadow-div"></div>
              <div className="greenshow-div"></div>
            </div>
          </div>
        )}
      </div>
      {/* {isModal === "notVerified" && (
        <VerifyModal onClose={() => setIsModal(null)} />
      )} */}
    </div>
  );
}
