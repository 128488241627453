import AmlHashForm from "aml-hash-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
//includes
import { common } from "helpers";
import { api } from "helpers/api";
import { config } from "../../helpers/config";

function ForgotPassword() {
  // init
  const navigate = useNavigate();
  //state
  const [localData] = useState(common.localDataGet());
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
      ],
      value: localData?.email,
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  //api
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      otpGenerate();
    }
  };

  //api
  const otpGenerate = () => {
    setLoading(true);
    let payload = fields;
    if (config.type === "AGENT") {
      payload["userType"] = "AGENT";
    } else {
      payload["userType"] = "STUDENT";
    }
    let data = {
      url: "ids_forgot_password",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res) => {
      if (res.status === 500) {
        setError(res.data.error);
      } else if (res.status === 200) {
        common.localDataSet({
          email: fields.email,
          type: "passwordForgot",
        });
        navigate("/otp/verify");
      }
      setLoading(false);
    });
  };

  //render
  return (
    <div className="container-fluid content-wrap">
      <div className="row mt-5 mx-2">
        <div className="col-md-5 mt-4 mx-auto">
          <div className="box-header">
            <p className="title">Forgot Password</p>
          </div>
          <div className="box-body">
            <label className="form-label">Email Address</label>
            <div className="et-form-row">
              <input
                type="email"
                name="email"
                className="form-control input-email"
                value={fields.email}
                placeholder="Enter your email address"
                onChange={(e) => {
                  setError(null);
                  handleChange(e);
                }}
              />
              <div className=" email-fieldcheckbox checkbox-design">
                <div className="input-tick"></div>
              </div>
              <p className="error-txt">{error ? error : errors.email}</p>
            </div>

            {/* <div className="d-flex justify-content-between mt-3">
                  <div>
                    <Link to="/login" className="rememberlogin-text">
                      Sign In
                    </Link>
                  </div>
                </div> */}

            <div className="d-flex justify-content-center mt-4">
              <button
                className="btn-default"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Submit"}
              </button>
            </div>
          </div>
          <div className="shadow-div"></div>
          <div className="greenshow-div"></div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
